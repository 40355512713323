import U from '../../felles/Utils';
import SearchSel from '../../models/SearchSel';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateSearch;
}

export interface StateSearch {
  isSearchInputPopulated: boolean;
  searchSelectionOptions: SearchSel;
  searchInputValue: string;
  isSearchFieldFocused: boolean;
}

const initialState = {
  isSearchInputPopulated: false,
  searchSelectionOptions: new SearchSel(),
  searchInputValue: '',
  isSearchFieldFocused: false,
};

const setSearchInputPopulatedState = (state: StateSearch, action: any) => {
  return U.updateStore(state, { isSearchInputPopulated: action.payload });
}

const setSearchSelectionOptions = (state: StateSearch, action: any) => {
  return U.updateStore(state, { searchSelectionOptions: { ...action.payload } });
}

const setSearchInputValue = (state: StateSearch, action: any) => {
  return U.updateStore(state, { searchInputValue: action.payload });
}

const setSearchFieldFocused = (state: StateSearch, action: any) => {
  return U.updateStore(state, { isSearchFieldFocused: action.payload });
}

const searchReducer = (state = initialState, action: any = '') => {
  switch (action.type) {
    case actionTypes.SEARCH_FILLED_STATE_UPDATED:
      return setSearchInputPopulatedState(state, action);
    case actionTypes.SEARCH_SELECTION_OPTION_UPDATED:
      return setSearchSelectionOptions(state, action);
    case actionTypes.SEARCH_INPUT_VALUE_UPDATED:
      return setSearchInputValue(state, action);
    case actionTypes.SEARCH_INPUT_FOCUSED:
      return setSearchFieldFocused(state, action);
    default:
      return state;
  }
};

export default searchReducer;
