import React from 'react';
import profil from '../assets/images/profil.svg';

const SVGProfil = () => {
  return (
    <div className="vitec-profil">
      <img src={profil} alt="Vitec-Profil" />
    </div>
  );
};

export default SVGProfil;
