import { CircularProgress } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import React, { Component } from "react";
import Moment from 'react-moment';
import { connect } from 'react-redux';
import Utils from "../../../../felles/Utils";
import * as actions from '../../../../store/actions/index';
import { AppState } from "../../../../store/rootReducer";
import ACC1FormToolbar from "./ACC1FormToolbar";
import MenuItemTarget from "../../../../models/MenuItemTarget";
import MenuEl from "../../../../models/MenuEl";
import ACC1FormHeader from "./ACC1FormHeader";
import ACC1FormFooter from "./ACC1FormFooter";

class ACC1FormContent extends Component<Props> {
  state = {
    order: "desc",
    orderBy: "id",
    page: 0,
    rowsPerPage: -1 //10
  };

  componentDidMount() {
    this.props.getAccountungJobList();
  }

  componentDidUpdate() {
    if (this.props.menuItems?.length !== 0)
      this.props.setLocationPath(Utils.getLocationPatchFromMenuItem(undefined, 'ACC1', this.props.menuItems));
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.props.onRequestSelected(this.props.data.map(n => n.id));
      return;
    }
    this.props.onRequestSelected([]);
  };

  handleCheckboxClick = (event, id: number) => {
    event.stopPropagation();
    const selectedIndex = this.props.numSelectedJob.indexOf(id as number);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.props.numSelectedJob, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.props.numSelectedJob.slice(1));
    } else if (selectedIndex === this.props.numSelectedJob.length - 1) {
      newSelected = newSelected.concat(this.props.numSelectedJob.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.props.numSelectedJob.slice(0, selectedIndex),
        this.props.numSelectedJob.slice(selectedIndex + 1)
      );
    }
    this.props.onRequestSelected(newSelected);
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id: number) => this.props.numSelectedJob.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.data.length - page * rowsPerPage);
    //
    return (
      <div className={classes.root}>
        <Paper >
          <ACC1FormToolbar numSelected={this.props.numSelectedJob.length} />
          <TableContainer className={classes.container}>
            <div className={classes.tableWrapper}>
              <Backdrop className={classes.backdrop} open={this.props.isFetchingACC1List}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Table stickyHeader className={classes.table} size="small" aria-labelledby="tableTitle">
                <ACC1FormHeader
                  numSelected={this.props.numSelectedJob.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.props.data.length}
                />
                <TableBody>
                  {Utils.stableSort(this.props.data, Utils.getSorting(order, orderBy))
                    .map(n => {
                      const isSelected = this.isSelected(n.id as number);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell className="selectCheckbox" padding="checkbox">
                            <Checkbox onClick={event => this.handleCheckboxClick(event, n.id)} className="selectCheckbox" checked={isSelected} />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none">{n.id}</TableCell>
                          <TableCell className={classes.tableCell} align="center" component="th" scope="row" padding="none"><Moment format="YYYY.MM.DD HH:mm">{n.created}</Moment></TableCell>
                          <TableCell className={classes.tableCell} align="center">{n.job}</TableCell>
                          <TableCell className={classes.tableCell} align="center">{n.userid}</TableCell>
                          <TableCell className={classes.tableCell} align="center">{n.returncode}</TableCell>
                          <TableCell className={classes.tableCell} align="center">{n.customer}</TableCell>
                          <TableCell className={classes.tableCell} align="center">{n.cost}</TableCell>
                          <TableCell className={classes.tableCell} align="center">{n.reference}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}><TableCell colSpan={9} /></TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
          <ACC1FormFooter />
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginTop: 20,
    marginLeft: 40,
    marginRight: 40,
    paddingTop: 30,

    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      paddingTop: 0,
      marginTop: 8,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    height: `calc(96vh - ${210}px)`,
    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      height: `calc(95vh - ${240}px)`,
    },
  },
  paper: {
    width: '100vw',
    marginTop: 50,
  },
  table: {
    minWidth: 700,
    fontSize: 200,
  },
  tableCell: {
    paddingRight: 20,
    // padding: "0px 8px",
    width: '20px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

interface OwnProps {
  size: any,
  setCompactType?: (val: any) => void;
}
//
interface StateProps {
  data: any[];
  isFetchingACC1List: boolean;
  numSelectedJob: any[];
  onSelectAllClick?: any;
  order?: string;
  orderBy?: string;
  numSelected?: number;
  rowCount?: number;
  page?: number;
  rowsPerPage?: number;
  classes?: any;
  menuItemTarget?: MenuItemTarget;
  menuItems?: MenuEl[];
}
//
interface DispatchProps {
  onRequestSelected: (Selected: any[]) => void;
  getAccountungJobList: () => void;
  setLocationPath: (val: any) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

export function mapStateToProps(state: AppState): StateProps {
  return {
    data: state.accounting.accountingJobList,
    isFetchingACC1List: state.accounting.isFetchingACC1List,
    numSelectedJob: state.accounting.selectedJobList,
    menuItemTarget: state.menuItem.menuItemTarget,
    menuItems: state.menuItem.menuItems,
  }
}

export function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onRequestSelected: (Selected: number[]) => dispatch(actions.accountingJobListSelectedUpdated(Selected)),
    getAccountungJobList: () => dispatch(actions.accountingJobListGet()),
    setLocationPath: (val) => dispatch(actions.setLocationPath(val)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ACC1FormContent));