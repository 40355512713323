import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        vitecBt: {
            display: 'inline-block',
            color: '#fff',
            borderRadius: 3,
            background: '#961932',
            boxShadow: '0 0 5px -1px rgba(0,0,0,0.2)',
            cursor: 'pointer',
            verticalAlign: 'middle',
            width: '100px',
            padding: '5px',
            marginTop: '13px',
            textAlign: 'center',
            '&:hover': {
                background: '#cd5a6e',
                color: 'white',
                cursor: 'pointer',
            },
            '&:active': {
                color: 'white',
                background: '#cd5a6e',
            },
            '&:disabled': {
                color: 'white',
                background: '#e3e3e3',
            },
        }
    })
);


export default function ButtonVitec(props) {
    const classes = useStyles();
    //
    return (
        <Button type={props && props._type ? props._type : 'button'} className={classes.vitecBt}
            onClick={e => props._onclick(e)} disabled={props.disabled}
            style={{ fontSize: props._fontsize, width: props._width, height: props._height, margin: props._padding }}
            name={props._name} 
            >
            {props._label}
        </Button>
    );
}
