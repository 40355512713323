import Drawer from '@material-ui/core/Drawer';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UISize from '../models/UISize';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';
import LPNavigationWrapper from './LPNavigationWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    drawer: { width: 340, flexShrink: 0, },
    drawerPaper: { width: 340, background: 'transparent', border: '0px  solid' },

    '@media print' : { 
      drawer: {visibility: 'hidden',  width: 0,},
      drawerPaper: {visibility: 'hidden',  width: 0, },
    },
  }),
);

export default function LPDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  const open: boolean = useSelector(state => (state as AppState).ui.isDrawerOpened);
  const isButtonVisible: boolean = useSelector(state => (state as AppState).ui.isSandwichButtonTurnedOn);

  useEffect(() => {
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  }, [dispatch, screenSize]);
  //
  return (
    <Drawer className={classes.drawer} variant="persistent" anchor="left" open={ isButtonVisible ? true : open} classes={{ paper: classes.drawerPaper }}>
      <LPNavigationWrapper />
    </Drawer>
  );
}
