import * as actionTypes from './actionTypes';

export const accountingJobListGetInit = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_GET_INIT };
};

export const accountingJobListGet = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_GET };
};

export const accountingJobListGetFailed = (error: any) => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_GET_FAILED, error: error };
};

export const accountingJobListGetSuccess = (accountingJobList: any) => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_GET_SUCCESS, accountingJobList: accountingJobList };
};

export const accountingJobListSetDate = (date: any) => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SET_DATE, date };
};


/********************************** CSV *******************************/


export const accountingJobCVSListGetInit = () => {
  return { type: actionTypes.ACCOUNTING_JOB_CSV_LIST_GET_INIT };
};

export const accountingJobCVSListGet = () => {
  return { type: actionTypes.ACCOUNTING_JOB_CSV_LIST_GET };
};

export const accountingJobCVSListGetFailed = (error: any) => {
  return { type: actionTypes.ACCOUNTING_JOB_CSV_LIST_GET_FAILED, error: error };
};

export const accountingJobCVSListGetSuccess = () => {
  return { type: actionTypes.ACCOUNTING_JOB_CSV_LIST_GET_SUCCESS};
};


/***************************************************************** */

export const accountingJobListSelectedUpdated = (jobId: any[]) => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_UPDATED, jobId: jobId };
};

export const accountingJobListSelectedDeletionPostInit = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_INIT };
};

export const accountingJobListSelectedDeletionPost = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST };
};

export const accountingJobListSelectedDeletionPostSuccess = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_SUCCESS };
};

export const accountingJobListSelectedDeletionPostFailed = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_FAILED };
};

export const accountingJobListSelectedExportGetInit = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_INIT };
};

export const accountingJobListSelectedExportGet = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET };
};

export const accountingJobListSelectedExportGetSuccess = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_SUCCESS };
};

export const accountingJobListSelectedExportGetFailed = () => {
  return { type: actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_FAILED };
};

export const accountingDeletionDialogUpdated = (payload: boolean) => {
  return { type: actionTypes.ACCOUNTING_DELETE_DIALOG_UPDATED, payload };
};

