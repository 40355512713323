import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import GridLayout from '../../models/GridLayout';
import GridLayouts from '../../models/GridLayouts';
import MenuEl from '../../models/MenuEl';
import MenuItemTarget from '../../models/MenuItemTarget';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import LPContentCardVSE from '../../components/LPContentCardVSE';
import { history } from '../../store/history';
import Utils from '../../felles/Utils';

const styles = () => ({
  root: { width: '100%' /* border: '1px solid red',*/ }, //
  gridItem: { /*  borderRadius: 5, */ border: '1px solid #7c7980', height: 'auto ! important' },
});

interface Props {
  setLayouts: (layout: any, layouts: any) => void;
  setLayout: (layout) => void;
  setMoutedState: (val: boolean) => void;
  setDrawerState: (val: boolean) => void;
  setBreakpointState: (val: any) => void;
  setCompactType: (val: any) => void;
  onBreakpointChange: (val: any) => void;
  onCompactTypeChange: (val: any) => void;
  onLayoutChange: (val: any) => void;
  menuItemSetTarget: (val: any) => void;
  menuItemSetSelected: (val: any) => void;
  currentBreakpoint: string;
  compactType: any;
  mounted: boolean;
  layouts: any;
  layout: any;
  classes: any;
  drawerWidth: number;
  gridWidth: number;
  cols: any;
  width: number;
  height: number;
  isDrawerOpened: boolean;
  size: any;
  menuItemTarget: MenuItemTarget;
  menuItemList: MenuEl[];
  gridItemHeight: any[];
  isDebugEnabled: boolean;
  contextMenuVisible: boolean;
  contextMenuX: number;
  contextMenuY: number;
  isEndKeyActivated: boolean;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLayouts: (layout, layouts) => dispatch(actions.setGridLayouts(layout, layouts)),
    setLayout: (layout) => dispatch(actions.setGridLayout(layout)),
    setMoutedState: (val) => dispatch(actions.setMouted(val)),
    setDrawerState: (val) => dispatch(actions.setDrawerState(val)),
    setBreakpointState: (val) => dispatch(actions.setCurrentBreakPoint(val)),
    menuItemSetTarget: (val) => dispatch(actions.menuItemSetTarget(val)),
    menuItemSetSelected: (val) => dispatch(actions.menuItemSetSelected(val)),
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    currentBreakpoint: state.ui.currentBreakpoint,
    compactType: state.ui.compactType,
    mounted: state.ui.mounted,
    layout: state.ui.layout,
    layouts: state.ui.layouts,
    drawerWidth: state.ui.drawerWidth,
    gridWidth: state.ui.gridWidth,
    cols: state.ui.cols,
    height: state.ui.height,
    isDrawerOpened: state.ui.isDrawerOpened,
    menuItemTarget: state.menuItem.menuItemTarget,
    menuItemList: state.menuItem.menuItems,
    gridItemHeight: state.ui.gridItemHeight,
    isDebugEnabled: state.utils.isDebugEnabled,
    // isEndKeyActivated: state.trans.isEndKeyActivated,
  };
};

class LPContentItem extends Component<Props> {
  //
  ValidKey: string[] = ['end'];

  //Init
  componentDidMount() {
    
    // for the tablet IPAD / GALAX
    document.body.removeEventListener('touchmove', Utils.preventDefault );
    //
    this.props.setLayouts(this.generateGridLayouts()[this.props.currentBreakpoint], this.generateGridLayouts());
    if (this.props.currentBreakpoint === 'xs' || this.props.currentBreakpoint === 'xxs') this.props.setDrawerState(false);
    this.props.setMoutedState(true);
   
  }

  //
  onBreakpointChange = (breakpoint) => {
    return this.props.setBreakpointState(breakpoint);
  };

  //
  onCompactTypeChange = () => {
    return this.props.setCompactType('vertical');
  };

  //
  onLayoutChange = () => {
    return this.props.setLayouts(this.generateLayout(this.props.currentBreakpoint), this.generateGridLayouts());
  };

  //
  handlePressedEndKey(key, event: any = null) {
    event.preventDefault();
    const n: MenuItemTarget = this.props.menuItemTarget;
    let firstSub = '';
    this.props.menuItemList.forEach((item: MenuEl, i) => {
      if (item.nodes.length > 0) {
        if (n.rootItem === item.name) {
          firstSub = item.nodes[0].name;
        }
      }
    });
    this.props.menuItemSetSelected([n.rootItem, firstSub + n.rootItem]);
    this.props.menuItemSetTarget(new MenuItemTarget(n.rootItem, firstSub, '', '', 0, 0, 0, 0, false, n.title));
    history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
    return;
  }

  //
  handlePressedKey(key, event) {
    event.preventDefault();
    if (key === 'end') {
      this.handlePressedEndKey(key, event);
      return;
    }
  }

  // Need a Rewrite - Optimisation
  generateGridLayouts() {
    const itemOffsetLG = [0, 8, 16];
    let IndexOffsett = 0;
    const { menuItemTarget, menuItemList } = this.props;
    const gridLayouts: GridLayouts = new GridLayouts();
    if (menuItemList && menuItemList.length > 0) {
      menuItemList.forEach((item: MenuEl, i) => {
        if (item.name === menuItemTarget.rootItem && item.nodes.length > 1) {
          item.nodes.forEach((subItem: MenuEl, j) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl, j) => {
                gridLayouts.xxl.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.xl.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.lg.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.md.push(new GridLayout(8, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.sm.push(
                  new GridLayout(
                    12, 2 + em.nodes.length,
                    itemOffsetLG[IndexOffsett] === 8 ? itemOffsetLG[IndexOffsett] + 4 : itemOffsetLG[IndexOffsett],
                    0, `${j}`)
                );
                gridLayouts.xs.push(new GridLayout(24, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                gridLayouts.xxs.push(new GridLayout(24, 2 + em.nodes.length, itemOffsetLG[IndexOffsett], 0, `${j}`));
                if (IndexOffsett < 2) IndexOffsett++;
                else IndexOffsett = 0;
              });
            }
          });
        } else {
          // for menu item with no
          item.nodes.forEach((subItem: MenuEl, j) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl, j) => {
                const JSXSubItemElemmentsA: string[] = [];
                em.nodes.forEach((eem: MenuEl, je) => {
                  if (!JSXSubItemElemmentsA.includes(eem.heading)) JSXSubItemElemmentsA.push(eem.heading);
                });

                JSXSubItemElemmentsA.forEach((weem: String, o) => {
                  const JSXSubItemElemments: any = [];
                  em.nodes.forEach((eee: MenuEl, jee) => {
                    if (eee.heading === weem) {
                      JSXSubItemElemments.push(eee);
                    }
                  });

                  gridLayouts.xxl.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.xl.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.lg.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.md.push(new GridLayout(8, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.sm.push(
                    new GridLayout(
                      12,
                      2 + JSXSubItemElemments.length,
                      itemOffsetLG[IndexOffsett] === 8 ? itemOffsetLG[IndexOffsett] + 4 : itemOffsetLG[IndexOffsett],
                      0,
                      `${o}`
                    )
                  );
                  gridLayouts.xs.push(new GridLayout(24, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  gridLayouts.xxs.push(new GridLayout(24, 2 + JSXSubItemElemments.length, itemOffsetLG[IndexOffsett], 0, `${o}`));
                  if (IndexOffsett < 2) IndexOffsett++;
                  else IndexOffsett = 0;
                });
              });
            }
          });
        }
      });
    }
    return gridLayouts;
  }

  subItemComposed = () => {
    const { menuItemTarget, menuItemList, classes } = this.props;
    const JSXItemElemments: any = [];
    if (menuItemList && menuItemList.length > 0) {
      menuItemList.forEach((item: MenuEl, i) => {
        if (item.name === menuItemTarget.rootItem && item.nodes.length > 1) {
          item.nodes.forEach((subItem: MenuEl, j) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl, j) => {
                const JSXSubItemElemments: any = [];
                em.nodes.forEach((eem: MenuEl, je) => {
                  JSXSubItemElemments.push(eem);
                });
                JSXItemElemments.push(
                  <div className={classes.gridItem} key={j}>
                    <SizeMe monitorWidth={false} monitorHeight={true}>
                      {({ size }) => (
                        <LPContentCardVSE isDebugEnabled={this.props.isDebugEnabled} key={'tree' + i} data={JSXSubItemElemments} title={em.heading} size={size} />
                      )}
                    </SizeMe>
                  </div>
                );
              });
            }
          });
        } /*(item.name === menuItemTarget.rootItem && item.nodes.length === 1) */ else {
          item.nodes.forEach((subItem: MenuEl, j) => {
            if (subItem.name === menuItemTarget.item) {
              subItem.nodes.forEach((em: MenuEl, j) => {
                const JSXSubItemElemmentsA: string[] = [];
                em.nodes.forEach((eem: MenuEl, je) => {
                  if (!JSXSubItemElemmentsA.includes(eem.heading)) JSXSubItemElemmentsA.push(eem.heading);
                });

                JSXSubItemElemmentsA.forEach((weem: String, o) => {
                  const JSXSubItemElemments: any = [];
                  em.nodes.forEach((eee: MenuEl, jee) => {
                    if (eee.heading === weem) {
                      JSXSubItemElemments.push(eee);
                    }
                  });
                  JSXItemElemments.push(
                    <div className={classes.gridItem} key={o}>
                      <SizeMe monitorWidth={false} monitorHeight={true}>
                        {({ size }) => (
                          <LPContentCardVSE isDebugEnabled={this.props.isDebugEnabled} key={'tree' + o} data={JSXSubItemElemments} title={weem} size={size} />
                        )}
                      </SizeMe>
                    </div>
                  );
                });
              });
            }
          });
        }
      });
    }

    return JSXItemElemments;
  };

  gridItemHeight = { xl: 25, lg: 15, md: 15, sm: 10, xs: 10, xxs: 10 };

  generateLayout(val) {
    const layouts = this.generateGridLayouts();
    return layouts[val];
  }

  render() {
    //
    const { classes } = this.props;
    //
    return (
      <>
        <Responsive
          autoSize={true}
          className={classes.root}
          layouts={this.generateGridLayouts()}
          width={this.props.size.width}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          measureBeforeMount={true}
          useCSSTransforms={this.props.mounted}
          isResizable={false}
          isDraggable={false}
          breakpoints={{ xxl: 3500, xl: 2220, lg: 1580, md: 1341, sm: 1026, xs: 500, xxs: 0 }}
          cols={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
          // previous  cols={{ xxl: 24, xl: 24,  lg: 24, md: 24, sm: 12, xs: 12, xxs: 12 }}
          margin={[30, 30]}
          rowHeight={this.gridItemHeight[this.props.currentBreakpoint]} //md= 10 LG = 19
        >
          {this.subItemComposed()}
        </Responsive>
        <KeyboardEventHandler handleKeys={this.ValidKey} onKeyEvent={(key, e) => this.handlePressedKey(key, e)} />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(LPContentItem));
