import { Button, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 50,
      '& .MuiButton-root': {
        marginTop: '5px',
        marginBottom: '15px',
        width: '80%',
        backgroundColor: '#961932',
        '&:hover': {
          borderColor: '#fff',
          backgroundColor: '#cd5a6e',
          boxShadow: 'none',
        },
      },

      '& .MuiInputBase-input': {
        height: 40,
        margin: 0,
        padding: 0,
        paddingLeft: '5px',
        '&:hover': {
          borderColor: '#f3f3f3',
          backgroundColor: '#f3f3f3',
        },

        /* XXS */[theme.breakpoints.between(0, 801)]: { height: 25 },
        /* XS  */[theme.breakpoints.between(801, 1367)]: { height: 25 },
        /* SM  */[theme.breakpoints.between(1367, 1681)]: { height: 35 },
        /* MD  */[theme.breakpoints.between(1681, 1921)]: { height: 40 },
        /* LD  */[theme.breakpoints.between(1921, 2561)]: { height: 60 },
        /* XL  */[theme.breakpoints.between(2561, 3841)]: { height: 60 },
        /* XXL */[theme.breakpoints.between(3841, 10000)]: { height: 60 },
      },


    },

    formroot: {
      margin: '5px auto',
      display: ' inline-block',
      width: '100%',
    },
    login: {
      width: '100%',
      textAlign: 'center',
    },
    fieldWrapper: {
      width: '80%',
      margin: '0 auto',
    },

    formTextField: { width: '100%' },
    formTextLabel: {
      width: '100%',
      marginTop: 10,
    },

    formSubmitBt: {
      width: '50px',
      textDecoration: 'none',

    },

    button: {
     width: '100px !important',
      fontSize: '.9em',

    },

    buttonWrapper: {
      width: '100%',
      textAlign: 'center',
    },

     /* XXS */[theme.breakpoints.between(0, 801)]: { root: { fontSize: '0.7em' }, buttonWrapper: { marginTop: -5 }, button: { height: 25, width: '100px !important', fontSize: '.8em' }, formTextLabel: { marginTop: 0,fontSize: '.9em' }, }, 
      /* XS  */[theme.breakpoints.between(801, 1367)]: { root: { fontSize: '0.8em' }, buttonWrapper: { marginTop: -1 }, button: { height: 25, width: '110px !important', fontSize: '.8em' }, formTextLabel: { marginTop: 0, fontSize: '.9em' }, },
      /* SM  */[theme.breakpoints.between(1367, 1681)]: { root: { fontSize: '0.8em' }, buttonWrapper: { marginTop: 0 }, button: { height: 30, width: '120px !important', fontSize: '.8em' }, formTextLabel: { marginTop: 4,fontSize: '.8em' }, }, 
      /* MD  */[theme.breakpoints.between(1681, 1921)]: { root: { fontSize: '.9em' }, buttonWrapper: { marginTop: 5 }, button: { height: 35, width: '150px !important', fontSize: '.8em' }, formTextLabel: { marginTop: 5,fontSize: '.8em' }, }, 
      /* LD  */[theme.breakpoints.between(1921, 2561)]: { root: { fontSize: '1em' }, buttonWrapper: { marginTop: 5 }, button: { height: 40, width: '200px !important', fontSize: '.8em' }, formTextLabel: { marginTop: 6 ,fontSize: '1em' }, }, 
      /* XL  */[theme.breakpoints.between(2561, 3841)]: { root: { fontSize: '1.1em' }, buttonWrapper: { marginTop: 20 }, button: { height: 45, width: '200px !important', fontSize: '.9em' }, formTextLabel: { marginTop: 8 ,fontSize: '1em' }, }, 
      /* XXL */[theme.breakpoints.between(3841, 10000)]: { root: { fontSize: '1.2em' }, buttonWrapper: { marginTop: 50 }, button: { height: 50, width: '200px !important', fontSize: '1em' }, formTextLabel: { marginTop: 10 ,fontSize: '1em' }, }, 

 
  //Samsung GalaxyTab
  '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
    formTextLabel: { marginTop: 0, fontSize: '.9em' },
  },

    
  // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    formTextLabel: {
      fontSize: '1em',
    },
  },


  })
);

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

export default function CAutodataWeb() {
  const classes = useStyles();

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  //
  function handleSubmit(event) {
    event.preventDefault();
    const newWindow = window.open(
      'https://gw2.autodata.no/signon.php?frm_ident=' + user + '&frm_passord=' + password + '&frm_action=200',
      '_blank',
      'noopener,noreferrer'
    );
    if (!user.includes('')) if (newWindow) newWindow.opener = null;
  }

  //
  function getAutodata() {
    //
    return (
      <>
        <form className={classes.formroot} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className={classes.fieldWrapper}>
            <div className={classes.formTextLabel}>Brukernavn</div>

            <TextField
              className={classes.formTextField}
              type="text"
              name="user"
              autoComplete="new-password"
              onInput={(e) => setUser((e.target as HTMLTextAreaElement).value)}
              placeholder=""
              variant="outlined"
              inputProps={{ tabIndex: 1 }}
            />

            <div className={classes.formTextLabel}>Passord</div>
            <TextField
              autoComplete="new-password"
              className={classes.formTextField}
              type="password"
              name="password"
              onInput={(e) => setPassword((e.target as HTMLTextAreaElement).value)}
              placeholder=""
              variant="outlined"
              inputProps={{ tabIndex: 2 }}
            />
          </div>
          <div>
            <div className="row marginTopBottom5"> </div>
          </div>
          <div className={classes.buttonWrapper}>
            <Button type="submit" variant="contained" color="primary" disableElevation tabIndex="3" className={classes.button}>
              Logg inn
            </Button>
          </div>
        </form>
      </>
    );
  }
  return <div className={classes.root}>{getAutodata()}</div>;
}
