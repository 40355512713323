export default class UserUpdatePayload {

  private _username: string;
  private _firstname: string;
  private _lastname: string;
  private _email: string;
  private _emailConfirmation: string;
  private _mobile: string;

  constructor(username = '', firstname = '', lastname = '', email = '', emailConfirmation = '', mobile = '') {
    this._username = username;
    this._firstname = firstname;
    this._lastname = lastname;
    this._email = email;
    this._emailConfirmation = emailConfirmation;
    this._mobile = mobile;
  }

  
  public get username(): string {
    return this._username;
  }

  public set username(value: string) {
    this._username = value;
  }

  public get firstname(): string {
    return this._firstname;
  }

  public set firstname(value: string) {
    this._firstname = value;
  }

  public get lastname(): string {
    return this._lastname;
  }

  public set lastname(value: string) {
    this._lastname = value;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }



  public get emailConfirmation(): string {
    return this._emailConfirmation;
  }

  public set emailConfirmation(value: string) {
    this._emailConfirmation = value;
  }


  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(value: string) {
    this._mobile = value;
  }



}
