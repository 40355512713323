export default class PassParam {
  //private _user: string;
  private _passwordA: string;
  private _passwordB: string;
  private _token: string;

  constructor(/*user: string, */passwordA = '', passwordB = '', token = '') {
  //  this._user = user;
    this._passwordA = passwordA;
    this._passwordB = passwordB;
    this._token = token;
  }
/*
  get user(): string {
    return this._user;
  }
*/
  get passwordA(): string {
    return this._passwordA;
  }

  get passwordB(): string {
    return this._passwordB;
  }

  get token(): string {
    return this._token;
  }
}
