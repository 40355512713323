import GridLayout from "./GridLayout";

export default class GridLayouts {
  public xxl: GridLayout[] = [];
  public xl: GridLayout[] = [];
  public lg: GridLayout[] = [];
  public md: GridLayout[] = [];
  public sm: GridLayout[] = [];
  public xs: GridLayout[] = [];
  public xxs: GridLayout[] = [];

}
