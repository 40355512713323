import U from '../../felles/Utils';
import ACC1 from '../../models/ACC1';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateAccounting;
}

export interface StateAccounting {
  accountingJobList: ACC1[];
  selectedJobList: number[];
  redirectPath: string;
  error: any;
  isFetchingACC1List: boolean;
  date: Date;
  isAccountingDialogOpen: boolean;
  jobId: any;
}

const initialState = {
  accountingJobList: [],
  selectedJobList: [],
  redirectPath: '/',
  error: undefined,
  isFetchingACC1List: false,
  date: new Date(),
  isAccountingDialogOpen: false,
  jobId: undefined,
};

const accountingJobListSetDate = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { date: action.date });
};

const accountingJobListGetInit = (state: StateAccounting) => {
  return U.updateStore(state, { isFetchingACC1List: true });
};

const accountingJobListGetSuccess = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { accountingJobList: action.accountingJobList, isFetchingACC1List: false });
};

const accountingJobListGetFailed = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { isFetchingACC1List: false, error: action });
};

const accountingJobListSelectedUpdated = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { selectedJobList: action.jobId });
};

const accountingJobListSelectedDeletionPostInit = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { isFetchingACC1List: false, error: action });
};

const accountingJobListSelectedDeletionPostSuccess = (state: StateAccounting) => {
  const tempData = [...state.accountingJobList];
  state.selectedJobList.forEach((element) => {
    tempData.forEach((n, i) => {
      if (element === n.id) {
        tempData.splice(i, 1);
      }
    });
  });
  return U.updateStore(state, { isFetchingACC1List: false, isAccountingDialogOpen: false, accountingJobList: tempData, selectedJobList: [] });
};

const accountingJobListSelectedDeletionPostFailed = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { isFetchingACC1List: false, error: action });
};

const accountingJobListSelectedExportGetInit = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { isFetchingACC1List: false, error: action });
};

const accountingJobListSelectedExportGetSuccess = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { isFetchingACC1List: false, error: action });
};

const accountingJobListSelectedExportFailed = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { isFetchingACC1List: false, error: action });
};

const accountingDeletionDialogUpdated = (state: StateAccounting, action: any) => {
  return U.updateStore(state, { isAccountingDialogOpen: action.payload });
};

const accountingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SET_DATE:
      return accountingJobListSetDate(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_GET_INIT:
      return accountingJobListGetInit(state);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_GET_SUCCESS:
      return accountingJobListGetSuccess(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_GET_FAILED:
      return accountingJobListGetFailed(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SELECTED_UPDATED:
      return accountingJobListSelectedUpdated(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_INIT:
      return accountingJobListSelectedDeletionPostInit(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_SUCCESS:
      return accountingJobListSelectedDeletionPostSuccess(state);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SELECTED_DELETION_POST_FAILED:
      return accountingJobListSelectedDeletionPostFailed(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_INIT:
      return accountingJobListSelectedExportGetInit(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_SUCCESS:
      return accountingJobListSelectedExportGetSuccess(state, action);
    //
    case actionTypes.ACCOUNTING_JOB_LIST_SELECTED_EXPORT_GET_FAILED:
      return accountingJobListSelectedExportFailed(state, action);

    //
    case actionTypes.ACCOUNTING_DELETE_DIALOG_UPDATED:
      return accountingDeletionDialogUpdated(state, action);

    default:
      return state;
  }
};

export default accountingReducer;
