import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import React from "react";
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';

const footerStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1)
    },
    button: {
        width: 150,
        marginRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    }
});

const ACC1FormFooter = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    function sendRequest() {
        dispatch(actions.accountingJobCVSListGet());
    }

    return (
        <Toolbar className={classNames(classes.root)}>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                <Button variant="contained" onClick={() => sendRequest()} className={classNames(classes.button)}>Export (.csv)</Button>
            </div>
        </Toolbar>
    );
};

export default withStyles(footerStyles)(ACC1FormFooter);
