import U from '../../felles/Utils';
import FirmNavnId from '../../models/FirmNavnId';
import LdapGroup from '../../models/LdapGroup';
import LdapUser from '../../models/LdapUser';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateUserAdmin;
}

export interface StateUserAdmin {
  userLDAPData: any | LdapUser;
  userSelectedGroupList: string[];
  userDataList: LdapUser[];
  groupListFromAD: string[];
  groupListFromAdmin: LdapGroup[];
  selectedUserList: string[];
  redirectPath: string;
  error: any;
  isFetchingUserList: boolean;
  isFetchingUserCSVList: boolean;

  isFetchingUserData: boolean;
  isUpdatingUserData: boolean;
  isDeletingUserData: boolean;
  isFetchingroupData: boolean;
  isRegisteringUser: boolean;
  userEditState: number;
  userRegistrationState: number;
  userDeletionState: number;
  date: Date;
  isUserDialogueOpen: boolean;
  userId: any;
  firmListFromAdmin: FirmNavnId[];
  isFetchingAdminFirmData: boolean;
}

const initialState = {
  userLDAPData: undefined,
  userSelectedGroupList: [],
  userDataList: [],
  groupListFromAD: [],
  groupListFromAdmin: [],
  selectedUserList: [],
  redirectPath: '/',
  error: undefined,
  isFetchingUserList: false,
  isFetchingUserCSVList: false,

  isFetchingUserData: false,
  isUpdatingUserData: false,
  isDeletingUserData: false,
  isFetchingroupData: false,
  isRegisteringUser: false,
  userEditState: 0,
  userRegistrationState: 0,
  userDeletionState: 0,
  date: new Date(),
  isUserDialogueOpen: false,
  userId: undefined,
  firmListFromAdmin: [],
  isFetchingAdminFirmData: false,
};


const setUserEditState = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { userEditState: action.payload });
};

const setUserRegistrationState = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { userRegistrationState: action.payload });
};

const setUserDeletionState = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { userDeletionState: action.payload });
};

const userListSetDate = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { date: action.date });
};

/**************************************************************************************************/
const userListFetchStart = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingUserList: true });
};

const userListFetchedSuccess = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { userDataList: action.userDataList, isFetchingUserList: false });
};

const userListFetchedFailed = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { isFetchingUserList: false, error: action });
};

const userListSelectedUpdated = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { selectedUserList: action.userId });
};

/************************************************************************************ */
/************************************************************************************ */
/************************************ DELETION ************************************** */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
const userListSelectedDeletionStarted = (state: StateUserAdmin) => {
  return U.updateStore(state, { isDeletingUserData: true });
};

const userListSelectedDeletionSuccess = (state: StateUserAdmin) => {
  return U.updateStore(state, { isDeletingUserData: false });
};

const userListSelectedDeletionFailed = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { isFetchingUserList: false, error: action });
};

const userDeletionDialogUpdated = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { isUserDialogueOpen: action.payload });
};



/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
const groupListGetStarted = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingroupData: true });
};

const groupListGetSuccess = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { groupListFromAD: action.groupListFromAD, groupListFromAdmin: action.groupListFromAdmin, isFetchingroupData: false });
};

const groupListGetFailed = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { isFetchingroupData: false, error: action });
};


/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
const userDataFetchStarted = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingUserData: true });
};

const userDataFetchSuccess = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { userLDAPData: action.userLDAPData, groupListFromAdmin: action.groupListFromAdmin, isFetchingUserData: false });
};

const userDataFetchFailed = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { isFetchingUserData: false, error: action });
};

/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */

const userDataUpdateStarted = (state: StateUserAdmin) => {
  return U.updateStore(state, { isUpdatingUserData: true });
};

const userDataUpdateSuccess = (state: StateUserAdmin) => {
  return U.updateStore(state, { isUpdatingUserData: false, userEditState: 2, userLDAPData: undefined });
};

const userDataUpdateFailed = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { isUpdatingUserData: false, userEditState: 3, error: action });
};

/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */

const userRegistrationStarted = (state: StateUserAdmin) => {
  return U.updateStore(state, { isRegisteringUser: true, userRegistrationState: 1 });
};

const userRegistrationSuccess = (state: StateUserAdmin) => {
  return U.updateStore(state, { isRegisteringUser: false, userSelectedGroupList: [], userRegistrationState: 2 });
};

const userRegistrationFailed = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { /*isFetchingUserList: false*/ isRegisteringUser: false, userSelectedGroupList: [], userRegistrationState: 3, error: action });
};


const setUserSelectedGroupList = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { userSelectedGroupList: action.userSelectedGroupList });
};




/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */


const userListExportToCVSInit = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingUserCSVList: true });
};

const userListExportToCVS = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingUserCSVList: true });
};

const userListExportToCVSSuccess = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingUserCSVList: true });
};

const userListExportToCVSFailed = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingUserCSVList: true });
};


/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
/************************************************************************************ */
const firmListFromDBGetStarted = (state: StateUserAdmin) => {
  return U.updateStore(state, { isFetchingAdminFirmData: true });
};

const firmListFromDBGetSuccess = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { firmListFromAdmin: action.firmListFromAdmin, isFetchingAdminFirmData: false });
};

const firmListFromDBGetFailed = (state: StateUserAdmin, action: any) => {
  return U.updateStore(state, { isFetchingAdminFirmData: false, error: action });
};


const userAdminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    //
    case actionTypes.USER_LIST_SET_DATE:
      return userListSetDate(state, action);
    //
    case actionTypes.USER_LIST_GET_INIT:
      return userListFetchStart(state);
    //
    case actionTypes.USER_LIST_GET_SUCCESS:
      return userListFetchedSuccess(state, action);
    //
    case actionTypes.USER_LIST_GET_FAILED:
      return userListFetchedFailed(state, action);
    //
    case actionTypes.USER_LIST_SELECTED_UPDATED:
      return userListSelectedUpdated(state, action);
    //
    case actionTypes.USER_LIST_SELECTED_DELETION_POST_INIT:
      return userListSelectedDeletionStarted(state);
    //
    case actionTypes.USER_LIST_SELECTED_DELETION_POST_SUCCESS:
      return userListSelectedDeletionSuccess(state);
    //
    case actionTypes.USER_LIST_SELECTED_DELETION_POST_FAILED:
      return userListSelectedDeletionFailed(state, action);
    //



    ///////////////////////////////////////////////////////////////////////////////
    case actionTypes.USER_DATA_GET_INIT:
      return userDataFetchStarted(state);
    //
    case actionTypes.USER_DATA_GET_SUCCESS:
      return userDataFetchSuccess(state, action);
    //
    case actionTypes.USER_DATA_GET_FAILED:
      return userDataFetchFailed(state, action);

    ///////////////////////////////////////////////////////////////////////////////
    case actionTypes.USER_DATA_UPDATE_POST_INIT:
      return userDataUpdateStarted(state);
    //
    case actionTypes.USER_DATA_UPDATE_POST_SUCCESS:
      return userDataUpdateSuccess(state);
    //
    case actionTypes.USER_DATA_UPDATE_POST_FAILED:
      return userDataUpdateFailed(state, action);


    ///////////////////////////////////////////////////////////////////////////////
    case actionTypes.USER_REGISTRATION_POST_INIT:
      return userRegistrationStarted(state);
    //
    case actionTypes.USER_REGISTRATION_POST_SUCCESS:
      return userRegistrationSuccess(state);
    //
    case actionTypes.USER_REGISTRATION_POST_FAILED:
      return userRegistrationFailed(state, action);

    //
    case actionTypes.USER_DELETE_DIALOG_UPDATED:
      return userDeletionDialogUpdated(state, action);

    case actionTypes.USER_STATE_EDIT:
      return setUserEditState(state, action);

    case actionTypes.USER_STATE_REGISTRATION:
      return setUserRegistrationState(state, action);


    case actionTypes.USER_STATE_DELETE:
      return setUserDeletionState(state, action);

    case actionTypes.USER_GROUP_LIST_FROM_LDAP_SET_SELECTED:
      return setUserSelectedGroupList(state, action);

    case actionTypes.USER_GROUP_LIST_FROM_LDAP_GET_INIT:
      return groupListGetStarted(state);

    case actionTypes.USER_GROUP_LIST_FROM_LDAP_GET_SUCCESS:
      return groupListGetSuccess(state, action);

    case actionTypes.USER_GROUP_LIST_FROM_LDAP_GET_FAILED:
      return groupListGetFailed(state, action);

    case actionTypes.USER_LIST_EXPORT_TO_CVS_INIT:
      return userListExportToCVSInit(state);

    case actionTypes.USER_LIST_EXPORT_TO_CVS:
      return userListExportToCVS(state);

    case actionTypes.USER_LIST_EXPORT_TO_CVS_SUCCESS:
      return userListExportToCVSSuccess(state);

    case actionTypes.USER_LIST_EXPORT_TO_CVS_FAILED:
      return userListExportToCVSFailed(state);


    case actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET_INIT:
      return firmListFromDBGetStarted(state);

    case actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET_SUCCESS:
      return firmListFromDBGetSuccess(state, action);

    case actionTypes.ADMIN_FIRM_LIST_FROM_DB_GET_FAILED:
      return firmListFromDBGetFailed(state, action);


















    default:
      return state;
  }
};

export default userAdminReducer;
