export default class MenuItemTarget {
  private _rootItem: string;
  private _item: string;
  private _subitem: string;
  private _code: string;
  private _hasChildren: boolean;
  private _title: string;
  private _tilpasset: number;
  private _tilpassetBeta: number;
  private _kodeSynlig: number;
  private _labelSynlig: number;


  constructor(rootItem = '', item = '', subitem = '', code = '', tilpasset = 0, tilpassetBeta = 0, kodeSynlig = 0, labelSynlig = 0, hasChildren = false, title = '') {
    this._rootItem = rootItem;
    this._item = item;
    this._subitem = subitem;
    this._code = code;
    this._tilpasset = tilpasset;
    this._tilpassetBeta = tilpassetBeta;
    this._kodeSynlig = kodeSynlig;
    this._labelSynlig = labelSynlig;
    this._hasChildren = hasChildren;
    this._title = title;
  }

  get item(): string {
    return this._item;
  }

  set item(value: string) {
    this._item = value;
  }

  get subitem(): string {
    return this._subitem;
  }

  set subitem(value: string) {
    this._subitem = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get tilpasset(): number {
    return this._tilpasset;
  }

  set tilpasset(value: number) {
    this._tilpasset = value;
  }

  get tilpassetBeta(): number {
    return this._tilpassetBeta;
  }

  set tilpassetBeta(value: number) {
    this._tilpassetBeta = value;
  }

  get kodeSynlig(): number {
    return this._kodeSynlig;
  }

  set labelSynlig(value: number) {
    this._labelSynlig = value;
  }

  get rootItem(): string {
    return this._rootItem;
  }

  set rootItem(value: string) {
    this._rootItem = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get hasChildren(): boolean {
    return this._hasChildren;
  }

}
