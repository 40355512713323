
import U from '../../felles/Utils';
import SupportFormData from '../../models/SupportFormData';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';


export interface FeatureState extends fromApp.AppState {
  state: StateSupport;
}

export interface StateSupport {
  supportFormData: SupportFormData;
  isSupportFetchInProgress: boolean;
  isSupportDialogueOpen: boolean;


  isAutoErrorSendingPostInProgress: boolean;
  isAutoErrorSendingPostDialogueOpen: boolean;


  feedbackPostState: number
}

const initialState = {
  supportFormData: new SupportFormData(),
  isSupportFetchInProgress: false,
  isSupportDialogueOpen: false,

  isAutoErrorSendingPostInProgress: false,
  isAutoErrorSendingPostDialogueOpen: false,


  feedbackPostState: 0,


};

const sendFeedbackToSupportPostInitiated = (state: StateSupport) => {
  return U.updateStore(state, { isSupportFetchInProgress: true });
}

const sendFeedbackToSupportPost = (state: StateSupport, action: any) => {
  return U.updateStore(state, { supportFormData: action.payload });
}

const sendFeedbackToSupportPostSuccess = (state: StateSupport, action: any) => {
  return U.updateStore(state, { isSupportFetchInProgress: false, content: action.payload });
}

const sendFeedbackToSupportPostFailed = (state: StateSupport, action: any) => {
  return U.updateStore(state, { isSupportFetchInProgress: false, isSupportDialogueOpen: true, error: action });
}






const AutoErrorSendingPostStarted = (state: StateSupport) => {
  return U.updateStore(state, { isAutoErrorSendingPostInProgress: true });
}

const AutoErrorSendingPost = (state: StateSupport) => {
  return U.updateStore(state, { isAutoErrorSendingPostInProgress: true });
}

const AutoErrorSendingPostSuccess = (state: StateSupport) => {
  return U.updateStore(state, { isAutoErrorSendingPostInProgress: false });
}

const AutoErrorSendingPostFailed = (state: StateSupport) => {
  return U.updateStore(state, { isAutoErrorSendingPostInProgress: false });
}



const setAutoErrorSendingState = (state: StateSupport, action: any) => {
  return U.updateStore(state, { feedbackPostState: action.payload });
}












const supportReducer = (state = initialState, action: any = '') => {
  switch (action.type) {
    case actionTypes.SUPPORT_GET_INIT:
      return sendFeedbackToSupportPostInitiated(state);
    case actionTypes.SUPPORT_GET:
      return sendFeedbackToSupportPost(state, action);
    case actionTypes.SUPPORT_GET_SUCCESS:
      return sendFeedbackToSupportPostSuccess(state, action);
    case actionTypes.SUPPORT_GET_FAILED:
      return sendFeedbackToSupportPostFailed(state, action);
    case actionTypes.AUTO_ERROR_SEND_POST_INIT:
      return AutoErrorSendingPostStarted(state);
    case actionTypes.AUTO_ERROR_SEND_POST:
      return AutoErrorSendingPost(state);
    case actionTypes.AUTO_ERROR_SEND_POST_SUCCESS:
      return AutoErrorSendingPostSuccess(state);
    case actionTypes.AUTO_ERROR_SEND_POST_FAILED:
      return AutoErrorSendingPostFailed(state);
    case actionTypes.AUTO_ERROR_SENT_STATE:
      return setAutoErrorSendingState(state, action);
    case actionTypes.DESTROY_SESSION:
      return initialState;
    default:
      return state;
  }
};

export default supportReducer;
