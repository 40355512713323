import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';
import Utils from '../../felles/Utils';
import {
  autoLoginToAutodata, getRequestLinkPasswordLinkRecovery, loginToAutodata, logoutFromHeirloomAPITerminal, requestOfPasswordChange
} from '../../felles/APICalls_SPRING';
import UserCred from '../../models/UserCred';
import * as actions from '../actions';
import * as selectors from '../selectors';


export function* formLoginSent(action: any) {

  let isLoginAutodata = true;
  const btsg = '' + new Date().getTime();

  /******************************************************  Login to AUTODATA's API  **************************************************** */
  yield put(actions.userLoginToAutodataGetInit());
  try {
    const resAutodata = yield loginToAutodata(action.userCred, btsg);
    
    // Login registration disabled for now
    // yield autoLoginLog(action.userCred, btsg);

    if (resAutodata && resAutodata.data) {
      sessionStorage.setItem('token', resAutodata.data.token);
      sessionStorage.setItem('btsg', btsg);
      yield put(actions.setTabId(btsg));
      yield put(actions.updateAutodataUserAuth(true));
      yield put(actions.updateAutodataUserJWT(resAutodata.data.token));
      yield put(actions.setUserAuthenticatedAgainstAutodataAPI(true));
    }
  } catch (error) {
    isLoginAutodata = false;
    yield put(actions.userLoginToAutodataGetFailed(error));
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('btsg');
  }
  if (isLoginAutodata) {
    yield put(push(`${Utils.HOMEPAGE_CONTEXT}/menu`));
    yield put(actions.setIsLogged(2));
  } else {
    yield put(actions.setLoginDialogOpeningState(true));
  }
}

/************************************************************************************************************ */
/************************************************ AUTO LOGIN ************************************************ */
/************************************************************************************************************ */
/************************************************************************************************************ */

//TODO: Fabrice => find a solution why Axios pops up Basic Auth alert.
export function* autoLoginformLoginSent(action: any) {
  //const isLogged = yield select(selectors.isLogged);
  const btsg = '' + new Date().getTime();
  // Login to AUTODATA's API
  yield put(actions.userLoginToAutodataGetInit());
  try {
    const resAutodata = yield autoLoginToAutodata(action.token, btsg);
    if (resAutodata && resAutodata.data) {
      sessionStorage.setItem('token', resAutodata.data.token);
      sessionStorage.setItem('btsg', btsg);
      yield put(actions.setTabId(btsg));
      yield put(actions.updateAutodataUserAuth(true));
      yield put(actions.updateAutodataUserJWT(resAutodata.data.token));
      yield put(actions.setUserAuthenticatedAgainstAutodataAPI(true));
      yield put(actions.setUserAuthenticatedAgainstHeirloomAPI(true));
      yield put(actions.setSessionUIState(1));
      yield put(actions.setIsLogged(1));
      //
      if (!action.performSilent) {
        if (action.transId.toString() === '') {
          yield put(push(`${Utils.HOMEPAGE_CONTEXT}/menu`));
        } else {
          yield put(push(`${Utils.HOMEPAGE_CONTEXT}/vse?transid=${action.transId}`));
        }
      }
    }
  } catch (error) {
    yield put(actions.userLoginToAutodataGetFailed(error));
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('btsg');
  }
  yield put(actions.setIsloading(false));
}

/************************************************************************************************************ */
/*************************************************** LOGOUT ************************************************* */
/************************************************************************************************************ */
/************************************************************************************************************ */

export function* formLogoutSent(action: any /* No refresh / redirection*/) {
  try {
    yield put(actions.userLogoutStarted());
    if (sessionStorage.getItem('token')) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('btsg');
      yield logoutFromHeirloomAPITerminal();
      const http = new XMLHttpRequest();
      http.open('get', Utils.HEIRLOOM_SERVICE + '/menu.html', false, 'BAD', 'BAD');
      http.send('');
      if (http.status === 401) {
        yield put(actions.userLogoutSuccess());
      } else {
        // Logout frontend from Heirloom's API
        yield put(actions.heirloomAuthUpdated(new UserCred('', '', '', false)));
      }
    }
    // We keep a few variables after reinitializing the state
    yield put(actions.onClear(action.loginState, action.isAlreadyLoggedFromAnotherTab, action.tabListSize, action.activeTab));
    //
    if (action.loginState === 4) {
      const tabId = yield select(selectors.tabId);
      yield put(push(`${Utils.HOMEPAGE_CONTEXT}/logout`));
      Utils.message_broadcast({
        command /* SENT */: 'SEND_THIS_STAMP_TO_OTHER_TAB',
        tabId,
        isLogged: 4,
        random: new Date().getTime().toString() + Math.random(),
      });
      yield put(actions.setIsLogged(4));
    }
    yield put(actions.setIsloading(false));
  } catch (error) {
    yield put(actions.userLogoutFailed(error));
  }
}

/*
 * Call to backend to get a recovery password link by email
 */
export function* formPasswordLinkRecovery(action: any) {
  try {
    yield put(actions.userPasswordLinkRecoveryPostInit());
    yield getRequestLinkPasswordLinkRecovery(action.userCred);
    yield put(actions.userPasswordLinkRecoveryPostSuccess());
  } catch (error) {
    if ((error as any).response) {
      yield put(actions.userPasswordLinkRecoveryPostFailed((error as any)?.response?.data));
    } else {
      yield put(actions.userPasswordLinkRecoveryPostFailed(error));
    }
  }
}

/*
 * Call to backend saving the new passowrd
 */
export function* formPasswordReset(action: any) {
  try {
    yield put(actions.userPasswordResetStarted());
    yield requestOfPasswordChange(action.passParam);
    yield put(actions.userPasswordResetPostSuccess());
  } catch (error) {
    // move that under userPasswordResetPostFailed
    if ((error as any).response) {
      yield put(actions.userPasswordResetPostFailed((error as any)?.response?.data));
    } else {
      yield put(actions.userPasswordResetPostFailed(error));
    }
  }
}
