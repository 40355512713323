import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LPNavigationLogout from './LPNavLogout';
import LPNavMenuTree from './LPNavMenuTree';
import LPNavigationProfile from './LPNavProfile';
import LPNavigationSearch from './LPNavSearch';

const useStyles = makeStyles({
  root: {
    height: '100%',
    maxWidth: 400,
    backgroundColor: '#222328',
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 16,
    padding: 16,
  },
  lowerM: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    width: 'auto',
    paddingBottom: 5,
    paddingLeft: 16,
    backgroundColor: '#222328',
  },
});

export default function LPNavigationWrapper() {
  const classes = useStyles();
  //
  return (
    <div className={classes.root}>
      <div>
        <LPNavigationSearch />
        <LPNavMenuTree />
      </div>
      <div className={classes.lowerM}>
        <LPNavigationProfile />
        <LPNavigationLogout />
      </div>
    </div>
  );
}
