


export default class SupportFormData {
  //

  private _from: string;
  private _subject: string;
  private _description: string;
  private _fileName: string;
  private _formData: FormData;

  //
  constructor(from = '', subject = '', description = '', fileName = '', formData: FormData = new FormData()) {
    this._from = from;
    this._subject = subject;
    this._description = description;
    this._fileName = fileName;
    this._formData = formData;
  }


  get from(): string {
    return this._from;
  }

  get subject(): string {
    return this._subject;
  }

  get description(): string {
    return this._description;
  }

  get fileName(): string {
    return this._fileName
  }

  get formData(): FormData {
    return this._formData;
  }

}

