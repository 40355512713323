import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import LPAppBar from '../../components/LPAppBar';
import LPDrawer from '../../components/LPDrawer';
import LPDrawerHeader from '../../components/LPDrawerHeader';
import ErrorContent from "./ErrorContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', height: '100vh', width: '100vw', },

    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }), marginLeft: 0,
    },

  }),
);

export default function LPError() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <LPAppBar />
      <LPDrawer />
      <main className={classes.contentShift}>
        <LPDrawerHeader />
        <ErrorContent />
      </main>
    </div>
  );
}
