
import classNames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { AppState } from '../../../../store/rootReducer';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Button, IconButton, Toolbar, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingRight: theme.spacing(1)
        },
        highlight: {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 1)
        },
        spacer: { flex: "1 1 100%" },
        actions: { color: theme.palette.text.secondary },
        buttonA: { width: 250, marginRight: theme.spacing(1) },
        title: { flex: "0 0 auto" }
    }),
);



const CriteriaListTableToolbar = (/*props*/) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const selectedCriteriaItemList: number[] = useSelector(state => (state as AppState).tallenesTale.selectedCriteriaItemList);
    const setTaleDeletionState = () => dispatch(actions.setCriteriaDeletionState(1));
    const user: any = useSelector((state) => (state as AppState).auth.userJWT);


    const sendRequest = () => dispatch(actions.setCriteriaRegistrationState(1));

    return (
        <Toolbar
            className={classNames(classes.root, { [classes.highlight]: selectedCriteriaItemList?.length > 0 })}>
            <div className={classes.title}>
                {selectedCriteriaItemList?.length > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {selectedCriteriaItemList?.length} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">Statistisk rapport søkekriterier for {user?.company?.toString()}</Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {selectedCriteriaItemList?.length > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete" onClick={() => setTaleDeletionState()}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : <Button className={classes.buttonA} variant="contained" onClick={() => sendRequest()}>Oppretting av varegruppe</Button>}
            </div>
        </Toolbar>
    );
};

export default CriteriaListTableToolbar;
