
import { CssBaseline } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SizeMe } from 'react-sizeme';
import Utils from '../../felles/Utils';
import MenuItemTarget from '../../models/MenuItemTarget';
import UISize from '../../models/UISize';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import LPAppBar from '../../components/LPAppBar';
import LPDrawer from '../../components/LPDrawer';
import UserList from './components/UserList';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', height: '100vh', width: '100vw' },
    content: {
      marginTop: 40,
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -340,
    },
    contentShift: {
      //marginTop: 15,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
);

export default function PageUserList(/*props*/) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const screenSize: UISize = useSelector(state => (state as AppState).ui.screenSize);
  const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;
  const n: MenuItemTarget = useSelector((state) => (state as AppState).menuItem.menuItemTarget);
  //
  const handlePressedKey = (event) => {
    const { key } = event;
    if ((event.shiftKey && key === 'End') || key === 'F3') {
      dispatch(actions.menuItemSetTarget(new MenuItemTarget(n.rootItem, n.item !== '' ? n.item : n.rootItem, '', '', n.tilpasset, n.tilpassetBeta, n.kodeSynlig, n.labelSynlig, n.hasChildren, n.title)));
      dispatch(actions.menuItemSetSelected([n.rootItem, n.item + n.rootItem]));
      history.push(Utils.HOMEPAGE_CONTEXT + '/menu');
    }
  }

  const button: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);

  return (
    <div tabIndex={0} onKeyDown={(e) => handlePressedKey(e)}>
      <div className={classes.root}>
        <CssBaseline />
        <LPDrawer />
        <main className={clsx(classes.content, { [classes.contentShift]: button ? true : open })}>
          <LPAppBar ismenuvisible={true} />
          <SizeMe>{({ size }) => <UserList size={size} />}</SizeMe>
        </main>
      </div >
    </div>
  );
}