import * as actionTypes from './actionTypes';


export const setHasError = (payload: any) => {
  return { type: actionTypes.SET_HAS_ERROR, payload };
};


export const setError = (payload: any) => {
  return { type: actionTypes.SET_ERROR, payload };
};


export const setErrorInfo = (payload: any) => {
  return { type: actionTypes.SET_ERROR_INFO, payload };
};

