import * as fromRouter from 'connected-react-router';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import accountingReducer, * as fromAccounting from './reducers/accounting.reducers';
import authReducer, * as fromAuth from './reducers/auth.reducers';
import errorReducer, * as fromError from './reducers/error.reducers';
import menuItemReducer, * as fromMenuItem from './reducers/menuIterm.reducers';
import searchReducer, * as fromSearch from './reducers/search.reducers';
import profileReducer, * as fromProfile from './reducers/profile.reducers';
import transReducer, * as fromTrans from './reducers/trans.reducers';
import uiReducer, * as fromUI from './reducers/ui.reducers';
import utilsReducer, * as fromUtils from './reducers/utils.reducers';
import supportReducer, * as fromSupport from './reducers/support.reducers';
import userAdminReducer, * as fromUserAdmin from './reducers/userAdmin.reducers';
import tallenesTaleReducer, * as fromTallenesTale from './reducers/tallenesTale.reducers';
export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    profile: profileReducer,
    utils: utilsReducer,
    support: supportReducer,
    accounting: accountingReducer,
    menuItem: menuItemReducer,
    search: searchReducer,
    ui: uiReducer,
    trans: transReducer,
    error: errorReducer,
    userAdmin: userAdminReducer,
    tallenesTale: tallenesTaleReducer,
  });

export type AppState = {
  router: fromRouter.RouterState;
  auth: fromAuth.StateAuthCred;
  profile: fromProfile.StateProfile;
  utils: fromUtils.StateUtils;
  accounting: fromAccounting.StateAccounting;
  menuItem: fromMenuItem.StateMenuItem;
  search: fromSearch.StateSearch;
  ui: fromUI.StateUI;
  trans: fromTrans.StateTrans;
  error: fromError.StateError;
  userAdmin: fromUserAdmin.StateUserAdmin;
  tallenesTale: fromTallenesTale.STALE;
  support: fromSupport.StateSupport;
};
