import { withStyles } from '@material-ui/core';
import classNames from "classnames";
import React from "react";


const footerStyles = theme => ({
  root: { width: 700, marginTop: 150, marginLeft: 50, paddingRight: theme.spacing(1), }
});


const SupportContent = (props) => {
  const { classes } = props;


  return (
    <div className={classNames(classes.root)}>
      Not authorized. Redirecting....
    </div>
  );
}

export default withStyles(footerStyles)(SupportContent);