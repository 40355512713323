import * as actionTypes from './actionTypes';

export const pdfGetInit = () => {
  return { type: actionTypes.PDF_GET_INIT };
};

export const pdfGet = (payload: any) => {
  return { type: actionTypes.PDF_GET,  payload};
};

export const pdfGetSuccess = (payload:any) => {
  return { type: actionTypes.PDF_GET_SUCCESS, payload };
};

export const pdfGetFailed = (error: any) => {
  return { type: actionTypes.PDF_GET_FAILED, error };
};


