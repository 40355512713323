
import { put } from 'redux-saga/effects';
import { getMenuItems } from '../../felles/APICalls_SPRING';
import * as actions from '../actions';

export function* getMenuItemSaga() {
  try {
    yield put(actions.menuItemGetInit());
    const response = yield getMenuItems();
    yield put(actions.menuItemGetSuccess(response.data));
  } catch (error) {
    yield put(actions.menuItemGetFailed(error));
  }
}
