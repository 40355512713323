import { ConnectedRouter } from 'connected-react-router';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer, /* hot, */ setConfig } from 'react-hot-loader';
import { Provider } from 'react-redux';
import App from './App';
import './assets/styles/styles.scss';
import * as actions from './store/actions/index';
import configureStore from './store/_-_-_-_-__-_-_-_-__configureStore';
import { history } from './store/history';
// import { AppState } from './store/rootReducer';  EX. (store.getState() as AppState).auth.isLogged);

setConfig({ showReactDomPatchNotification: false });

const store: any = configureStore();
const token = sessionStorage.getItem('token');

if (token) {
  store.dispatch(actions.updateAutodataUserAuth(true));
  store.dispatch(actions.updateAutodataUserJWT(token));
  store.dispatch(actions.userAutologinToAutodataAuthGet(token, '', true, 0/*, 0*/)); // No transID | silent login | stateLogout
}

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  );
};

render();
// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render();
  }); //
}
