import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import LogoSimple from '../../components/SVGLogoSimple';
import Utils from '../../felles/Utils';
import PassParam from '../../models/PassParams';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250, width: 350, padding: 20, position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)', borderRadius: 10, backgroundColor: 'rgba(255, 255, 255, 0.85)',
    '& .MuiTextField-root': { marginBottom: '15px', width: '100%', },
  },
}));

/*
 * PasswordResetLink
 */
export default function PasswordUpdate() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPath = window.location.toString();
  const token = currentPath.substring(currentPath.lastIndexOf('/#') + 2, currentPath.length);
  const [passwordA, setPasswordA] = useState('');
  const [passwordB, setPasswordB] = useState('');
  const feedbackResetDialog: any = useSelector((state) => (state as AppState).auth.feedbackResetDialog);
  const open = useSelector((state) => (state as AppState).auth.isPasswordResetDialogOpen);
  const isResetButtonVisible = useSelector((state) => (state as AppState).auth.isFeedbackResetCloseButtonDialogVisible);
  const isPasswordChangedSuccessfully = useSelector((state) => (state as AppState).auth.isPasswordChangedSuccessfully);

  // TODO: Add the missing validation criterias upper/ lower/ number
  function isPasswordValidated(p1 = '', p2 = ' ') {
    let isPassVal = true;
    const tempfeedbackResetDialog: any[] = [];
    if (
      passwordA.toLowerCase().includes('ø')
      || passwordA.toLowerCase().includes('å')
      || passwordA.toLowerCase().includes('æ')) {
      isPassVal = false;
      tempfeedbackResetDialog.push([
        <React.Fragment>
          <br />
          <span>Ikke bruk ÆØÅ i ditt passord.</span>
          <br />
        </React.Fragment>,
      ]);
    }
    // Password must be min 8 char
    if (p1.length < 16 || p1.length > 23) {
      isPassVal = false;
      tempfeedbackResetDialog.push([
        <React.Fragment>
          <span>Passordet bør ha minst en lengde på minst 16 tegn og maksimalt 23 tegn.</span>
          <br />
        </React.Fragment>,
      ]);
    }
    // Password must match
    if (p1 !== p2) {
      isPassVal = false;
      tempfeedbackResetDialog.push([
        <React.Fragment>
          <span>Passordene må matche.</span>
          <br />
        </React.Fragment>,
      ]);
    }
    if (tempfeedbackResetDialog.length !== 0) {
      dispatch(actions.updateResetDialog(tempfeedbackResetDialog));
    }
    return isPassVal;
  }
  //
  function handleDiagClose(val: boolean) {
    dispatch(actions.passwordResetDialogClosed(val));
    if (isPasswordChangedSuccessfully && val && isPasswordValidated(passwordA, passwordB)) history.push(Utils.HOMEPAGE_CONTEXT + '/home');
  }
  //
  function handleSubmit(event) {
    event.preventDefault();
    handleDiagClose(false);
    if (isPasswordValidated(passwordA, passwordB)) {
      const tempfeedbackResetDialog: any[] = [];
      tempfeedbackResetDialog.push([
        <React.Fragment>
          <br />
          <span>Vennligst vent...</span>
          <br />
        </React.Fragment>,
      ]);
      dispatch(actions.updateResetDialog(tempfeedbackResetDialog));
      dispatch(actions.userPasswordResetFetch(new PassParam(passwordA, passwordB, token)));
    }
  }
  //
  return (
    <div>
      <Dialog open={!open} onClose={() => handleDiagClose(true)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Tilbakestilling av passord'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{feedbackResetDialog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {isResetButtonVisible ? (
            <Button onClick={() => handleDiagClose(true)} color="primary" autoFocus>
              Ok
            </Button>
          ) : (
            []
          )}
        </DialogActions>
      </Dialog>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className="form-login__logo">
          <LogoSimple width="150" />
        </div>
        <div className="form-login__h2 centerer marginTopBottom20">Skriv inn nytt passord</div>
        <div className="form-login__h4 centered marginTopBottom20"><strong>Minimum 16, maksimum 23 karakterer<br /><br />Ikke bruk tidligere benyttet passord, og ikke bruk ÆØÅ i ditt passord.</strong></div>
        <div className="form-login__textfield marginTopBottom10">
          <TextField type="password" name="passwordA" onInput={(e) => setPasswordA((e.target as HTMLTextAreaElement).value)} variant="outlined" />
        </div>
        <div className="form-login__textfield marginTopBottom10">
          <div className="form-login__h2 marginTopBottom20">Gjenta nytt passord</div>
          <TextField type="password" name="passwordB" onInput={(e) => setPasswordB((e.target as HTMLTextAreaElement).value)} variant="outlined" />
        </div>
        <div className="form-login__submit-button centered marginTopBottom10">
          <Button type="submit" variant="contained" color="primary" disableElevation>
            LAGRE
          </Button>
        </div>
        <div className="centered marginTopBottom10">
          <NavLink to={Utils.HOMEPAGE_CONTEXT + '/home'}>Tilbake til innlogging</NavLink>{' '}
        </div>
      </form>
    </div>
  );
}
