import { CircularProgress, makeStyles, Tooltip } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import classNames from 'classnames';
import React, { createRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ButtonVitec from '../../../components/ButtonVitec';
import DialogVitec from '../../../components/DialogVitec';
import LdapGroup from '../../../models/LdapGroup';
import LdapUser from '../../../models/LdapUser';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: '#fff',
    fontSize: '1em',
    color: 'black',
    border: '1px solid #fff',
    margin: 0,
    marginTop: 10,
    marginLeft: -5,
  },
  customArrow: {
    color: ' #fff',
  },
  root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },

  formWrapper: {
    width: '100vw',
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '93vh',
    padding: 20,
  },
  formAdd: {
    marginTop: 'min(20%)',
    marginBottom: 'min(20%)',
    boxShadow: '3px 3px 3px #222',
    horizontalAlign: 'center',
    backgroundColor: '#f3f3f3',
    width: 630,
    margin: '0 auto',
    padding: 30,
    border: '1px solid #333',
    borderRadius: '5px',
  },
  myInput: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
  myLabel: { width: 600, fontSize: '1.2em' },
  myTitle: { width: 600, fontSize: '1.6em', marginBottom: '30px', color: 'black' },
  formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
  checkboxWrapper: { color: '#000' },
  checkbox: { display: 'inline-flex', marginLeft: 10, marginBottom: 10 },
  grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
  myButtons: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-295px)' },
  myButtonsB: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-50px)' },
  myButton: { padding: '10px', margin: '0px 5px 0px 5px' },
  myButtonB: { padding: '10px', margin: '0px 10px 0px 10px' },
  dialogCustomizedWidth: { 'min-width': '400px', 'max-width': '400px', 'min-height': '150px' },

  myDialogTitle: {
    textAlign: 'center',
    fontSize: '1.4em',
    width: '100%',
  },

  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },
    formWrapper: { padding: 5 },
    formAdd: { width: 700, height: 500, padding: 15 },
    myInput: { width: '100%', padding: '5px', marginBottom: '7px', marginTop: '0px', border: '1px solid #ccc' },
    myLabel: { width: 500, fontSize: '1em' },
    myTitle: { width: 500, fontSize: '1.2em', marginBottom: '10px', color: 'black' },
    formError: { fontSize: '1.4em', marginTop: '30px', color: 'red', align: 'center' },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
    checkboxWrapper: { color: '#000' },
    checkbox: { display: 'inline-flex', marginLeft: 5, marginBottom: 5 },
    grpCheckbox: { marginLeft: 4, color: '#000', align: 'top' },
    myButtons: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-295px)' },
    myButtonsB: { display: 'inline-flex', verticalAlign: 'top', margin: '0 50%', transform: 'translateX(-50px)' },
    myButton: { padding: '10px', margin: '0px 5px 0px 5px' },
    myButtonB: { padding: '10px', margin: '0px 10px 0px 10px' },
    dialogCustomizedWidth: { 'min-width': '400px', 'max-width': '400px', 'min-height': '150px' },
  },
}));

const UserEdit = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLDAPData: LdapUser = useSelector((state) => (state as AppState).userAdmin.userLDAPData);
  const isFetchingUserData: boolean = useSelector((state) => (state as AppState).userAdmin.isFetchingUserData);

  const { register, getValues, setValue, handleSubmit, formState: { errors }, } = useForm()
  const formRef: any = createRef();
  const userEditState: number = useSelector((state) => (state as AppState).userAdmin.userEditState);
  const isUpdatingUserData: boolean = useSelector((state) => (state as AppState).userAdmin.isUpdatingUserData);
  const userSelectedGroupList: string[] = useSelector((state) => (state as AppState).userAdmin.userSelectedGroupList);
  const groupListFromAdmin: LdapGroup[] = useSelector((state) => (state as AppState).userAdmin.groupListFromAdmin);
  /**/
  useEffect(() => {
    if (!groupListFromAdmin) dispatch(actions.groupListGet());
    if (userLDAPData?.memberOf) {
      const list = userLDAPData.memberOf.map((user: string) => user.substring(3, user.indexOf(',')));
      dispatch(actions.setUserSelectedGroupList(list));
      const _target: HTMLFormElement = formRef.current;
      for (let i = 0; i < _target.length; i++) {
        //   if (_target.elements[i].nodeName.toString() === 'INPUT') {
        if (list.includes(_target.elements[i].id)) {
          _target.elements[i].setAttribute('checked', 'true');
        }
      }
      //
      setValue('firstname', userLDAPData.firstname);
      setValue('lastname', userLDAPData.lastname);
      setValue('email', userLDAPData.email);
      setValue('emailConfirmation', userLDAPData.email);
      setValue('mobile', userLDAPData.mobile);
      setValue('groups', list);
    }
  }, [setValue, userLDAPData, groupListFromAdmin]);

  //
  const onSubmit = (data: any = undefined) => {
    dispatch(
      actions.userDataUpdated(
        new LdapUser(
          userLDAPData.username,
          data.firstname,
          data.lastname,
          data.mobile,
          '', // company = '',
          '', // displayName = '',
          '', // OperatorId = '',
          '', // userPrincipalName = '',
          data.email,
          '', // lastLogonTimestamp: string,
          data.memberOf,
          userSelectedGroupList,
          false //send pwd
        )
      )
    );
  };

  //
  const onSubmitWithPWD = (data: any = undefined) => {
    dispatch(
      actions.userDataUpdated(
        new LdapUser(
          userLDAPData.username,
          data.firstname,
          data.lastname,
          data.mobile,
          '', // company = '',
          '', // displayName = '',
          '', // OperatorId = '',
          '', // userPrincipalName = '',
          data.email,
          '', // lastLogonTimestamp: string,
          data.memberOf,
          userSelectedGroupList,
          true //send pwd
        )
      )
    );
  };

  //
  const onCancel = () => {
    dispatch(actions.setUserEditState(0));
    dispatch(actions.userDataFetchSuccess(undefined, []));
  };

  //
  const handleDiagValidateUserSaved = () => {
    dispatch(actions.userListFetch());
    dispatch(actions.setUserEditState(0));
  };
  //
  // const handleDiagClose = () => { };

  const handleCheckboxClick = (event, username: string) => {
    event.stopPropagation();
    const selectedIndex = userSelectedGroupList.indexOf(username as string);
    let newSelectedList: string[] = [];
    if (selectedIndex === -1) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList, username);
    } else if (selectedIndex === 0) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList.slice(1));
    } else if (selectedIndex === userSelectedGroupList.length - 1) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedList = newSelectedList.concat(userSelectedGroupList.slice(0, selectedIndex), userSelectedGroupList.slice(selectedIndex + 1));
    }
    dispatch(actions.setUserSelectedGroupList(newSelectedList));
    setValue('groups', newSelectedList);
  };

  const renderCheckboxList = () => {
    return groupListFromAdmin
      .sort((a: any, b: any) => a.name - b.name)
      .map((group: LdapGroup, index: number) => {
        return group.name !== 'ALLUSERS' ? (
          <Tooltip
            key={'tp' + group.name}
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title={'' + group.description}
            arrow
          >
            <div className={classes.checkbox} key={'dg' + group}>
              <input
                type="checkbox"
                className={classes.checkbox}
                id={group.name}
                name={group.name}
                key={'group' + group.name}
                onClick={(event) => {
                  handleCheckboxClick(event, group.name);
                }}
              />
              <div key={'c' + index} className={classes.grpCheckbox}>
                {group.name}
              </div>
            </div>
          </Tooltip>
        ) : null;
      });
  };
  //
  const generateUserEditionStates = () => {
    switch (true) {
      case userEditState === 1:
        return (
          <>
            {isUpdatingUserData ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                {userLDAPData && !isFetchingUserData ? (
                  <form ref={formRef} className={classes.formAdd}>
                    <div className={classes.myTitle}>Redigere bruker {userLDAPData.username}</div>
                    <label htmlFor="firstname" className={classNames(classes.myLabel)}>
                      Fornavn
                    </label>
                    <input
                      className={classNames(classes.myInput)}
                      type="text"
                      id="firstname"
                      {...register("firstname", { required: true, maxLength: 40 })}
                      onChange={(e) => {
                        setValue('firstname', (e.target as any).value);
                      }}
                    />
                    <label htmlFor="lastname" className={classNames(classes.myLabel)}>
                      Etternavn
                    </label>
                    <input
                      className={classNames(classes.myInput)}
                      type="text"
                      id="lastname"
                      {...register("lastname", { required: true, maxLength: 40 })}
                      onChange={(e) => {
                        setValue('lastname', (e.target as any).value);
                      }}
                    />
                    <label htmlFor="emailA" className={classNames(classes.myLabel)}>
                      E-post
                    </label>
                    <input
                      className={classNames(classes.myInput)}
                      type="email"
                      id="email"
                      {...register("email", { required: true, maxLength: 100 })}
                      onChange={(e) => {
                        setValue('email', (e.target as any).value);
                      }}
                    />
                    <label htmlFor="emailB" className={classNames(classes.myLabel)}>
                      Bekreft E-post
                    </label>
                    <input
                      className={classNames(classes.myInput)}
                      type="text"
                      id="emailConfirmation"
                      {...register("emailConfirmation", { validate: { emailEqual: (value) => value === getValues().email || 'Email confirmation error!' } })}
                      onChange={(e) => {
                        setValue('emailConfirmation', (e.target as any).value);
                      }}
                    />

                    <label htmlFor="mobile" className={classNames(classes.myLabel)}>
                      Mobil
                    </label>
                    <input
                      className={classNames(classes.myInput)}
                      type="tel" id="mobile" {...register("mobile", { required: true, maxLength: 40 })}
                      onChange={(e) => { setValue('mobile', (e.target as any).value); }}
                    />
                    <label htmlFor="groups" className={classNames(classes.myLabel)}>Grupper</label>
                    <br />
                    <br />
                    {
                      /*******************************************************************************/
                      /*******************************************************************************/
                      groupListFromAdmin ? renderCheckboxList() : null
                      /*******************************************************************************/
                      /*******************************************************************************/
                    }
                    <div className={classes.myButtons}>
                      <div className={classes.myButton}>
                        <ButtonVitec _onclick={handleSubmit(onSubmit)} _width={80} _height={37} _type={'submit'} _label={'Lagre'} />
                      </div>
                      <div className={classes.myButton}>
                        <ButtonVitec
                          _onclick={handleSubmit(onSubmitWithPWD)}
                          _width={335}
                          _height={37}
                          _type={'button'}
                          className={classes.myButton}
                          _label={'Lagre og send lenke for å lage passord'}
                        />
                      </div>
                      <div className={classes.myButton}>
                        <ButtonVitec _onclick={() => onCancel()} _width={80} _height={37} _type={'button'} _label={'Avbryt'} />
                      </div>
                    </div>
                    <div className={classes.formError}>
                      {errors.firstname && errors.firstname.type === 'required' ? <div>Vennligst skriv inn fornavnet ditt.</div> : <div>&nbsp;</div>}
                      {errors.firstname && errors.firstname.type === 'maxLength' && <div> Maks lengde av fornavnet er 40 bokstaver.</div>}
                      {errors.lastname && errors.lastname.type === 'required' && <div>Vennligst skriv inn fornavnet ditt.</div>}
                      {errors.lastname && errors.lastname.type === 'maxLength' && <div> Maks lengde av fornavnet er 40 bokstaver.</div>}
                      {errors.mobile && errors.mobile.type === 'required' && <div>Vennligst skriv inn mobilnummeret ditt.</div>}
                      {errors.mobile && errors.mobile.type === 'maxLength' && <div> Maks lengde av mobilnummeret er 20 nummer.</div>}
                    </div>
                  </form>
                ) : (
                  <CircularProgress color="inherit" />
                )}
              </>
            )}
          </>
        );
      case userEditState === 2:
        return (
          <DialogVitec>
            <div className={classes.myDialogTitle}>Brukerinformasjon lagret</div>
            <div className={classes.myButtonsB}>
              <div className={classes.myButtonB}>
                <ButtonVitec _onclick={handleDiagValidateUserSaved} _width={80} _height={37} _type={'button'} _label={'Ok'} />
              </div>
            </div>
          </DialogVitec>
        );
      default:
        return null;
    }
  };
  //
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={true}>
        {generateUserEditionStates()}
      </Backdrop>
    </div>
  );
};

export default UserEdit;
