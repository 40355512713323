import React from 'react';
import mail from '../assets/images/mail.svg';

const SVGMail = (props) => {
  return (
    <div className="vitec-mail">
      <img src={mail} width={props.width} alt="Vitec-mail" />
    </div>
  );
};

export default SVGMail;
