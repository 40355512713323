export default class TaleCriteriaItem {
    private _id: number;
    private _firmId: string;
    private _varegruppe: string;
    private _navn: string;
    private _hovedgruppe: string;
    private _gruppe: string;
    private _summeringsgruppe: string;

    constructor(id: number, firmId: string, varegruppe: string, navn = '', hovedgruppe = '', gruppe = '', summeringsgruppe = '') {
        this._id = id
        this._firmId = firmId;
        this._varegruppe = varegruppe;
        this._navn = navn;
        this._hovedgruppe = hovedgruppe;
        this._gruppe = gruppe;
        this._summeringsgruppe = summeringsgruppe;
    }

    get id(): number {
        return this._id;
    }

    get firmId(): string {
        return this._firmId.toUpperCase();
    }

    get varegruppe(): string {
        return this._varegruppe;
    }

    get navn(): string {
        return this._navn;
    }

    get hovedgruppe(): string {
        return this._hovedgruppe;
    }

    get gruppe(): string {
        return this._gruppe;
    }

    get summeringsgruppe(): string {
        return this._summeringsgruppe;
    }

}
