


export default class NavFormData {
  //
  private _transId: string;
  private _scode: string;
  private _formData: string;
  private _termId: string;
  private _wsMessage: string;
  private _doPostToHttpViaAxios: boolean;
  private _doPostToWebsocket: boolean;
  private _isTransactionTerminated = false;

  //
  constructor(transId = '', scode = '', formData = '', termId = '', wsMessage = '',
    doPostToHttpViaAxios = false,
    doPostToWebsocket = false) {
    this._transId = transId;
    this._scode = scode;
    this._formData = formData;
    this._termId = termId;
    this._wsMessage = wsMessage;
    this._doPostToHttpViaAxios = doPostToHttpViaAxios;
    this._doPostToWebsocket = doPostToWebsocket;
  }

  get transId(): string {
    return this._transId;
  }

  get scode(): string {
    return this._scode;
  }

  get formData(): string {
    return this._formData;
  }

  get termId(): string {
    return this._termId;
  }

  get wsMessage(): string {
    return this._wsMessage;
  }

  get doPostToHttpViaAxios(): boolean {
    return this._doPostToHttpViaAxios;
  }

  set doPostToHttpViaAxios(val: boolean) {
    this._doPostToHttpViaAxios = val;
  }

  get doPostToWebsocket(): boolean {
    return this._doPostToWebsocket;
  }

  set doPostToWebsocket(val: boolean) {
    this._doPostToWebsocket = val;
  }

  get isTransactionTerminated(): boolean {
    return this._isTransactionTerminated;
  }
 
  set isTransactionTerminated(val: boolean) {
    this._isTransactionTerminated = val;
  }

}

