import React from 'react';
import phone from '../assets/images/phone.svg';

const SVGPhone = (props) => {
  return (
    <div className="vitec-phone">
      <img src={phone} width={props.width} alt="Vitec-phone" />
    </div>
  );
};

export default SVGPhone;
