export default class UISize {
  public width: number;
  public height: number;
  public pageYOffset: number;


  constructor(width: number, height: number, pageYOffset = 0) {
    this.width = width;
    this.height = height;
    this.pageYOffset = pageYOffset;
  }
/*
  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }
  */
}
