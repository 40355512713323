import * as actionTypes from './actionTypes';

export const supportStarted = () => {
  return { type: actionTypes.SUPPORT_GET_INIT };
};

export const supportFetchStarted = (payload: any) => {
  return { type: actionTypes.SUPPORT_GET,  payload};
};

export const supportFetchSuccess = (payload:any) => {
  return { type: actionTypes.SUPPORT_GET_SUCCESS, payload };
};

export const supportFetchFailed = (error: any) => {
  return { type: actionTypes.SUPPORT_GET_FAILED, error };
};

export const sendFeedbackToSupportPostInitiated = () => {
  return { type: actionTypes.SUPPORT_GET_INIT };
};

export const sendFeedbackToSupportPost = (payload: any) => {
  return { type: actionTypes.SUPPORT_GET,  payload};
};

export const sendFeedbackToSupportPostSuccess = (payload:any) => {
  return { type: actionTypes.SUPPORT_GET_SUCCESS, payload };
};

export const sendFeedbackToSupportPostFailed = (error: any) => {
  return { type: actionTypes.SUPPORT_GET_FAILED, error };
};

export const AutoErrorSendingPostStarted = () => {
  return { type: actionTypes.AUTO_ERROR_SEND_POST_INIT };
};

export const AutoErrorSendingPost = (payload: any) => {
  return { type: actionTypes.AUTO_ERROR_SEND_POST,  payload};
};

export const AutoErrorSendingPostSuccess = (payload:any) => {
  return { type: actionTypes.AUTO_ERROR_SEND_POST_SUCCESS, payload };
};

export const AutoErrorSendingPostFailed = (error: any) => {
  return { type: actionTypes.AUTO_ERROR_SEND_POST_FAILED, error };
};

export const setAutoErrorSendingState = (payload:any) => {
  return { type: actionTypes.AUTO_ERROR_SENT_STATE, payload };
};

