import UserUpdatePayload from '../../models/UserUpdatePayload';
import * as actionTypes from './actionTypes';

export const userProfileDataFetchStarted = () => {
  return { type: actionTypes.USER_PROFILE_DATA_GET_INIT };
};

export const userProfileDataFetch = () => {
  return { type: actionTypes.USER_PROFILE_DATA_GET };
};

export const userProfileDataFetchSuccess = (payload: any) => {
  return { type: actionTypes.USER_PROFILE_DATA_GET_SUCCESS, userProfileData: payload };
};

export const userProfileDataFetchFailed = ( error: any) => {
  return { type: actionTypes.USER_PROFILE_DATA_GET_FAILED, error: error };
};

export const userProfileDataUpdateStarted = () => {
  return { type: actionTypes.USER_PROFILE_DATA_UPDATE_POST_INIT };
};

export const userProfileDataUpdate = (userProfileData: UserUpdatePayload) => {
  return { type: actionTypes.USER_PROFILE_DATA_UPDATE_POST, userProfileData };
};

export const userProfileDataUpdateSuccess = (action: any) => {
  return { type: actionTypes.USER_PROFILE_DATA_UPDATE_POST_SUCCESS, isUserProfileDataUpdated: action };
};

export const userProfileDataUpdateFailed = (error: any) => {
  return { type: actionTypes.USER_PROFILE_DATA_UPDATE_POST_FAILED, error: error };
};


export const  userProfileDataDialogUpdated = (payload: boolean) => {
  return { type: actionTypes.USER_PROFILE_DATA_DIALOG_UPDATED, payload };
};


export const userProfileDataDialogSetMsg  = (payload: boolean) => {
  return { type: actionTypes.USER_PROFILE_DATA_DIALOG_SET_MSG, payload };
};

export const userProfileMemberStateUpdate = (inputName: string, value: string) => {
  return { type: actionTypes.USER_PROFILE_MEMBER_STATE_UPDATE, inputName, value };
};

