import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Help from './SVGHelp';
import PowerOff from './SVGPoweroff';
import UserCred from '../models/UserCred';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: '#fff',
    display: 'flex',
    margin: '10px 0px 0px 0px',
  },
  profileSVG: {
    width: '25%',
    margin: '5px 5px 5px 5px',
  },
  profileWrapperHelp: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#40424b',
    },
  },
  profileWrapperLogout: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#40424b',
    },
  },
  profileTxt: {
    fontSize: '1.2em',
    fontWeight: 500,
    margin: '0 10px',
  },

  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    root: { margin: '10px 0px 0px 0px' },
    profileSVG: { width: '15%', margin: '5px 5px 5px 5px' },
    profileWrapperHelp: { width: '100%' },
    profileTxt: { fontSize: '1em' },
  },
}));

export default function LPNavigationLogout() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const isLogged: number = useSelector((state) => (state as AppState).auth.isLogged);
  //const isLoading: boolean = useSelector((state) => (state as AppState).utils.isLoading);
  //const isLogged: number = useSelector((state) => (state as AppState).auth.isLogged);
  //const hasLoggedOut: number = useSelector((state) => (state as AppState).auth.hasLoggedOut);
  const isAlreadyLoggedFromAnotherTab: boolean = useSelector((state) => (state as AppState).auth.isAlreadyLoggedFromAnotherTab);
  const tabListSize: number = useSelector((state) => (state as AppState).auth.tabListSize);
  //const activeTab: boolean = useSelector((state) => (state as AppState).auth.activeTab);

  //
  const handleLogoutClicked = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(actions.setIsLogged(4));
    dispatch(actions.setTabAsActive(true));
    dispatch(
      actions.UserLogoutFetchValidation(
        new UserCred('BAD', 'BAD', '', false),
        false,
        4,
        // 2,
        isAlreadyLoggedFromAnotherTab,
        tabListSize,
        true /*activeTab*/
      )
    );
  };
  //
  const handleHelpClicked = (event) => {
    event.stopPropagation();
    event.preventDefault();
    window.open('https://www.vitec-autosystemer.com/dokumentasjon-autodata/', 'Support - Vitec', 'resizable,scrollbars,status');
  };
  //
  return (
    <div className={classes.root}>
      <div className={classes.profileWrapperHelp} onClick={handleHelpClicked}>
        <div className={classes.profileSVG}>
          <Help />
        </div>
        <div className={classes.profileTxt}>Hjelp</div>
      </div>
      <div className={classes.profileWrapperLogout} onClick={handleLogoutClicked}>
        <div className={classes.profileSVG}>
          <PowerOff />
        </div>
        <div className={classes.profileTxt}>Logg ut</div>
      </div>
    </div>
  );
}
