import React from 'react';
import help from '../assets/images/help.svg';

const SVGHelp = () => {
  return (
    <div className="vitec-Help">
      <img src={help} alt="Vitec-Help" />
    </div>
  );
};

export default SVGHelp;
