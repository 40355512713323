import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import React, { useRef } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';
import ButtonVitec from './ButtonVitec';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    omv: { color: '#000', margin: 15, border: '2px solid' },
    formOmv: { fontSize: '1.2em' },
    divbackground: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      backgroundColor: '#000',
      display: 'inline-block',
      zIndex: 1800,
      opacity: 0.4
    },
    wsContentOuter: {
      minWidth:  '810px',
      maxWidth: `calc(100vw - ${410}px)`,
      borderRadius: '5px',
      backgroundColor: '#fff',
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1810,
      paddingLeft: '30px',

      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    }
  })
);


export default function OpenMWVitec(props) {
  const classes = useStyles();
  const contentOMW: any = useSelector((state) => (state as AppState).trans.contentOMW);
  const ValidKey: string[] = ['esc', 'enter'];
  const omvFormRef: any = useRef();
  const dispatch = useDispatch();
  const setclickedBtn = (e) => {
    dispatch(actions.setOmvClickedBtn(e.currentTarget.name));
  }
  //
  const handlewebsocketResponseUpdate = (_wsResp: string) => {
    if (_wsResp.includes('<form')) {
      let websocketResponse: string = _wsResp.substring(_wsResp.indexOf('<form'), _wsResp.indexOf('</form') + 7);
      websocketResponse = websocketResponse.replace('<pre>', '');
      websocketResponse = websocketResponse.replace('</pre>', '');

      // Hack - remove some height
     // if (websocketResponse.includes('PALLNR'))
     //   for (let i = 0; i < 10; i++) websocketResponse = websocketResponse.replace('<br>', '');

      // processes children nodes 
      const secondPass = {
        replace: (domNode) => {
          if (!domNode.attribs) { return; }
          if (domNode.name.toString() === 'input') {
            const { /*onfocus, */ value, ...newAttribs } = domNode.attribs;
            //
            if (domNode.attribs.type === 'submit') {
              domNode.attribs.onMouseDown = (e) => dispatch(actions.setOmvClickedBtn(e.traget.value));
              return <ButtonVitec _type={'submit'} _fontsize={'1em'} _onclick={setclickedBtn} _name={domNode.attribs.name}
                _label={domNode.attribs.value} _width={150} _height={50} _padding={15} {...attributesToProps(domNode.attribs)} />;
            } else
              domNode.attribs = { ...newAttribs };
            domNode.attribs.class = classes.omv;
            return <input autoComplete="off" {...attributesToProps(domNode.attribs)} />;
          }
        },
      };
      // Cycles throught child nodes and modifies their attributes.
      const firstPass = {
        replace: (domNode) => {
          if (!domNode.attribs) { return; }
          if (domNode.name.toString() === 'form') {
            domNode.attribs = { ...domNode.attribs };
            domNode.attribs.class = classes.formOmv;
            return <>{domToReact(domNode.children, secondPass)}</>;
          }
        },
      };
      // Converts string to ReadtDomEl
      return <form onSubmit={(e) => props.onsubmit(e)} className={classes.formOmv}
        name={'omvForm'} id={'omvForm'} ref={omvFormRef} autoComplete="off" method={'post'}>
        <KeyboardEventHandler handleKeys={ValidKey} onKeyEvent={(key, e) => props.omvpressedkey(key, e)} />
        {(parse(websocketResponse, firstPass) as JSX.Element[])}
      </form>;
    }
  }
  //
  return (
    <>
      <div className={classes.divbackground} onClick={(e) => props.onsubmit(e)}></div>
      <div className={classes.wsContentOuter}>
        {contentOMW ? handlewebsocketResponseUpdate(contentOMW) : null}
      </div>
    </>
  );
}