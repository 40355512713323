export default class HjelpPostFormData {
  //
  private _from: string;
  private _group: string;
  private _prog: string;
  private _field: string;
  private _search: string;
   private _helpText: string;
  private _devText: string;

  //  new HjelpPostFormData(from, group, prog, field, text, devText);
  constructor(from = '', group = '', prog = '', field = '', search = '', helpText = '', devText = '') {
    this._from = from;
    this._prog = prog;
    this._field = field;
    this._search = search;
    this._group = group;
    this._helpText = helpText;
    this._devText = devText;
  }
  //
  get from(): string {
    return this._from;
  }
  //
  get prog(): string {
    return this._prog;
  }
  //
  get field(): string {
    return this._field;
  }
  //
  get search(): string {
    return this._search;
  }
  //
  get group(): string {
    return this._group;
  }
  //
  get helpText(): string {
    return this._helpText;
  }
  //
  get devText(): string {
    return this._devText;
  }
}
