import createSagaMiddleware from 'redux-saga';
import { history } from './history';
import rootReducer from './rootReducer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import {
    WatchCriterialListToExcelSaga,
    watchACC1CSVListFetch,
    watchACC1ListFetch,
    watchAccoutingJobListDeleteSelected,
    watchAccoutingJobListExportSelected,
    watchAuthAutoLoginSubmit,
    watchAuthLoginFormSubmit,
    watchAuthLogoutFormSubmit,
    watchAuthPassRecoveryFormSubmit,
    watchAuthPassResetFormSubmit,
    watchBugReportPostSaga,
    watchCriteriaItemDeletionSaga,
    watchCriteriaItemRegistrationSaga,
    watchCriteriaItemUpdateSaga,
    watchPostUploadExcelDocumentCriteria,
    watchFavorittListeDeletionSaga,
    watchFavorittListeGetReportSaga,
    watchFavorittListeListGetSaga,
    watchFavorittListeUpdateSaga,
    watchFetchMenuItems,
    watchGetAdminFirmListSaga,
    watchGetDriftPost,
    watchGetGroupListSaga,
    watchGetNyheterPost,
    watchGetPDFdata,
    watchGetTransById,
    watchGetUserList,
    watchGetUserListExportToData,
    watchHelpGet,
    watchLogToApiSaga,
    watchPostFeedbackdata,
    watchPostFormData,
    watchPostToWebSocket,
    watchUserDataFetchSaga,
    watchUserDataUpdateSaga,
    watchUserListDeletionSaga,
    watchUserLogouFromTerminal,
    watchUserProfileDataGet,
    watchUserProfileUpdateData,
    watchUserRegistrationSaga,
    watchcriteriaItemListGetSaga
} from './________sagas________';

export let store = undefined;

export default function configureStore() {
    const routerMiddlewaree = routerMiddleware(history);
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware, routerMiddlewaree];

    // ENABLE REDUX DEV TOOLS
    // const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
    const composeEnhancers = compose;
    if (process.env.NODE_ENV === 'development') {
        // middlewares.push(logger);
    }
    if (!store) {
        store = createStore(connectRouter(history)(rootReducer(history)), composeEnhancers(applyMiddleware(...middlewares)),);
    }

    // SAGAS Should be defined into dedicated files
    //AUTH USER
    sagaMiddleware.run(watchAuthLoginFormSubmit);
    sagaMiddleware.run(watchAuthAutoLoginSubmit);
    sagaMiddleware.run(watchAuthLogoutFormSubmit);
    sagaMiddleware.run(watchAuthPassRecoveryFormSubmit);
    sagaMiddleware.run(watchAuthPassResetFormSubmit);

    //ACCOUNTING
    sagaMiddleware.run(watchACC1ListFetch);
    sagaMiddleware.run(watchAccoutingJobListDeleteSelected);
    sagaMiddleware.run(watchAccoutingJobListExportSelected);
    sagaMiddleware.run(watchACC1CSVListFetch);

    //Menu
    sagaMiddleware.run(watchFetchMenuItems);

    //Heirloom
    sagaMiddleware.run(watchGetTransById);
    sagaMiddleware.run(watchHelpGet);
    sagaMiddleware.run(watchPostFormData);

    //PDF
    sagaMiddleware.run(watchGetPDFdata);

    //Profile
    sagaMiddleware.run(watchUserProfileDataGet);
    sagaMiddleware.run(watchUserProfileUpdateData);

    // Support
    sagaMiddleware.run(watchPostFeedbackdata);

    // Utils
    sagaMiddleware.run(watchGetNyheterPost);
    sagaMiddleware.run(watchGetDriftPost);
    sagaMiddleware.run(watchLogToApiSaga);

    // User Admin 
    sagaMiddleware.run(watchGetUserList);
    sagaMiddleware.run(watchGetUserListExportToData);
    sagaMiddleware.run(watchUserDataFetchSaga);
    sagaMiddleware.run(watchUserDataUpdateSaga);
    sagaMiddleware.run(watchUserRegistrationSaga);
    sagaMiddleware.run(watchUserListDeletionSaga);
    sagaMiddleware.run(watchGetGroupListSaga);
    sagaMiddleware.run(watchPostToWebSocket);
    sagaMiddleware.run(watchGetAdminFirmListSaga);
    sagaMiddleware.run(watchUserLogouFromTerminal);

    // Tallenes Tale
    sagaMiddleware.run(watchcriteriaItemListGetSaga);
    sagaMiddleware.run(watchCriteriaItemUpdateSaga);
    sagaMiddleware.run(watchCriteriaItemRegistrationSaga);
    sagaMiddleware.run(watchCriteriaItemDeletionSaga);
    sagaMiddleware.run(WatchCriterialListToExcelSaga);
    sagaMiddleware.run(watchFavorittListeListGetSaga);
    sagaMiddleware.run(watchFavorittListeUpdateSaga);
    sagaMiddleware.run(watchFavorittListeDeletionSaga);
    sagaMiddleware.run(watchFavorittListeGetReportSaga);
    sagaMiddleware.run(watchPostUploadExcelDocumentCriteria);
    sagaMiddleware.run(watchBugReportPostSaga);

    return store;
}