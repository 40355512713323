import U from '../../felles/Utils';
import UserUpdatePayload from '../../models/UserUpdatePayload';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateProfile;
}

export interface StateProfile {
  userProfileData: UserUpdatePayload;
  isFetchingUserProfileData: boolean;
  isUserProfileDataDialogueOpen: boolean;
  userProfileDataDialogueMsg: string;
  isUserProfileDataUpdated: boolean;
  error: string;
}

const initialState = {
  userProfileData: new UserUpdatePayload('', '', '', ''),
  isFetchingUserProfileData: false,
  isUserProfileDataDialogueOpen: false,
  userProfileDataDialogueMsg: '',
  isUserProfileDataUpdated: false,
  error: 'Profilen din er oppdatert',
};

const userProfileDataFetchStarted = (state: StateProfile) => {
  return U.updateStore(state, { isFetchingUserProfileData: true });
};

const userProfileDataFetch = (state: StateProfile) => {
  return U.updateStore(state, { isFetchingUserProfileData: true });
};

const userProfileDataFetchSuccess = (state: StateProfile, action: any) => {
  return U.updateStore(state, { isFetchingUserProfileData: false,  userProfileData: { ...action.userProfileData } });
};

const userProfileDataFetchFailed = (state: StateProfile, action: any) => {
  return U.updateStore(state, { isFetchingUserProfileData: false, error: action });
};

const userProfileDataUpdateStarted = (state: StateProfile) => {
  return U.updateStore(state, { isUserProfileDataUpdated: false, isFetchingUserProfileData: true });
};

const userProfileDataUpdate = (state: StateProfile) => {
  return U.updateStore(state, { isUserProfileDataUpdated: false, isFetchingUserProfileData: true });
};

const userProfileDataUpdateSuccess = (state: StateProfile, action: any) => {
  return U.updateStore(state, { isUserProfileDataUpdated: true, isFetchingUserProfileData: false, isUserProfileDataDialogueOpen: true, error: 'Profilen din er oppdatert', userProfileData: { ...action.userProfileData } });
};

const userProfileDataUpdateFailed = (state: StateProfile) => {
  return U.updateStore(state, { isUserProfileDataUpdated: false, isFetchingUserProfileData: false, isUserProfileDataDialogueOpen: true, error: 'ERROR! Profilen din er ikke oppdatert' });
};

const userProfileDataDialogUpdated = (state: StateProfile, action: any) => {
  return U.updateStore(state, { isUserProfileDataDialogueOpen: action.payload });
};

//const userProfileDataDialogSetMsg = (state: StateProfile, action: any) => { return U.updateStore(state, { userProfileDataDialogueMsg: action });};

const userProfileMemberStateUpdate = (state: StateProfile, action: any) => {
  return U.updateStore(state, { userProfileData: { ...state.userProfileData, [action.inputName]: action.value } });
}

const profileReducer = (state = initialState, action: any = undefined) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_DATA_GET_INIT:
      return userProfileDataFetchStarted(state);
    case actionTypes.USER_PROFILE_DATA_GET:
      return userProfileDataFetch(state);
    case actionTypes.USER_PROFILE_DATA_GET_SUCCESS:
      return userProfileDataFetchSuccess(state, action);
    case actionTypes.USER_PROFILE_DATA_GET_FAILED:
      return userProfileDataFetchFailed(state, action);
    case actionTypes.USER_PROFILE_DATA_UPDATE_POST_INIT:
      return userProfileDataUpdateStarted(state);
    case actionTypes.USER_PROFILE_DATA_UPDATE_POST:
      return userProfileDataUpdate(state);
    case actionTypes.USER_PROFILE_DATA_UPDATE_POST_SUCCESS:
      return userProfileDataUpdateSuccess(state, action);
    case actionTypes.USER_PROFILE_DATA_UPDATE_POST_FAILED:
      return userProfileDataUpdateFailed(state);
    case actionTypes.USER_PROFILE_DATA_DIALOG_UPDATED:
      return userProfileDataDialogUpdated(state, action);
    case actionTypes.USER_PROFILE_MEMBER_STATE_UPDATE:
      return userProfileMemberStateUpdate(state, action);
      
      case actionTypes.DESTROY_SESSION:
        return initialState;
    default:
      return state;
  }
};

export default profileReducer;