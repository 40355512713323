import { withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { AppState } from "../../../../store/rootReducer";


const rows = [
  { id: "id", numeric: false, disablePadding: true, label: "Id." },
  { id: "created", numeric: true, disablePadding: false, label: "Created" },
  { id: "job", numeric: true, disablePadding: false, label: "Job" },
  { id: "userid", numeric: true, disablePadding: false, label: "User Id." },
  { id: "returncode", numeric: true, disablePadding: false, label: "returncode" },
  { id: "customer", numeric: true, disablePadding: false, label: "Customer" },
  { id: "cost", numeric: true, disablePadding: false, label: "Cost" },
  { id: "reference", numeric: true, disablePadding: false, label: "reference" },
];

class ACC1FormHeader extends Component<Props> {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  //
  render() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.tableCell}>
            <Checkbox
              indeterminate={this.props.selectedJobList && this.props.selectedJobList.length > 0 && this.props.selectedJobList.length < this.props.rowCount}
              checked={this.props.selectedJobList?.length === this.props.rowCount}
              onChange={this.props.onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell className={classes.tableCell}
                key={row.id}
                align={row.numeric ? "center" : "center"}
                padding={row.disablePadding ? "normal" : "normal"}
                sortDirection={(this.props.orderBy === row.id ? (this.props.order === "asc" ? "asc" : "desc") : undefined)/**/}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={this.props.orderBy === row.id}
                    direction={(this.props.order === "asc" ? "asc" : "desc")}
                    onClick={this.createSortHandler(row.id)}
                  >{row.label}</TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}


const styles = () => ({ tableCell: { padding: "0px 0px", width: '20px', }, });

interface PassedPropsFromParent {
  onSelectAllClick: any;
  order: string;
  orderBy: string;
  rowCount: number;
  numSelected: number;
}

interface StateProps {
  classes?: any;
  selectedJobList?: number[];
}

interface DispatchProps {
  onRequestSort: (event: any, property: any) => void;
}

export type Props = PassedPropsFromParent & StateProps & DispatchProps;

const mapStateToProps = (state: AppState): StateProps | PassedPropsFromParent => {
  return {
    selectedJobList: state.accounting.selectedJobList,
  }
}

export default connect<StateProps | PassedPropsFromParent, DispatchProps, Props, AppState>(mapStateToProps/*, mapDispatchToProps*/)(withStyles(styles as any)(ACC1FormHeader));