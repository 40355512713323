import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useDispatch } from 'react-redux';
import * as actions from '../store/actions/index';

export default function ShiftHome(props) {
    const dispatch = useDispatch();
    const ValidKey: string[] = ['shift + home'];
    const handlePressedHomeKey = (key, event: any = null) => {
        event.preventDefault();
        dispatch(actions.setSearchInputFocused(true));
    }

    //
    return (
        <KeyboardEventHandler
            handleKeys={ValidKey}
            onKeyEvent={(key, e) => {
                handlePressedHomeKey(key, e);
            }}
        />
    );
}
