import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = (/*theme*/) => ({
  root: { marginLeft: 0, marginRight: 0, paddingTop: 0 },

  dialog: {

    boxShadow: '3px 3px 3px #222',
    horizontalAlign: 'center',
    backgroundColor: '#f3f3f3',
    width: 630,
    margin: '0 auto',
    padding: 30,
    border: '1px solid #333',
    borderRadius: '5px',
    color: '#000'
  },

  formWrapper: {
    width: '100vw',
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '93vh',
    padding: 20,
  },
});

const DialogVitec = (props) => {
  const { classes } = props;
  //const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <div className={classes.dialog}>{props.children}</div>
    </div>
  );
};

export default withStyles(styles)(DialogVitec);
