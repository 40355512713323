export default class ErrorMSG {


    private _transId: string;
    private _from: string;
    private _subject: string;
    private _error: string;
    private _formData: string;


    constructor(transId: string, from: string, subject: string, error = '', formData = '') {
        this._transId = transId;
        this._from = from;
        this._subject = subject;
        this._error = error;
        this._formData = formData;
    }


    public get transId(): string {
        return this._transId;
    }

    public set transId(value: string) {
        this._transId = value;
    }


    public get from(): string {
        return this._from;
    }

    public set from(value: string) {
        this._from = value;
    }


    public get subject(): string {
        return this._subject;
    }

    public set subject(value: string) {
        this._subject = value;
    }

    public get error(): string {
        return this._error;
    }

    public set error(value: string) {
        this._error = value;
    }


    public get formData(): string {
        return this._formData;
    }

    public set formData(value: string) {
        this._formData = value;
    }

}