export default class HjelpFormData {
  //
  private _transid = 'HJEP';
  private _DFH_CURSOR = 'DFH0007';
  private _DFH0003 = '';
  private _DFH0004 = '';
  private _DFH0005 = '';
  private _DFH0007 = '';
  private _DFH_ENTER = '';
  private _termid = '';
  private _btsg = '';
  //
  constructor(DFH0003 = 'ORRE', DFH0004 = '', DFH0007 = '', termid = '', btsg = '') {
    this._DFH0003 = DFH0003;
    this._DFH0004 = DFH0004;
    this._DFH0007 = DFH0007;
    this._termid = termid;
    this._btsg = btsg;
  }
  //
  get transid(): string {
    return this._transid;
  }
  //
  get termid(): string {
    return this._termid;
  }
  //
  get btsg(): string {
    return this._btsg;
  }

  //
  get DFH_CURSOR(): string {
    return this._DFH_CURSOR;
  }
  //
  get DFH0003(): string {
    return this._DFH0003;
  }
  //
  get DFH0004(): string {
    return this._DFH0004;
  }
  //
  get DFH0005(): string {
    return this._DFH0005;
  }
  //
  get DFH0007(): string {
    return this._DFH0007;
  }
  //
  get DFH_ENTER(): string {
    return this._DFH_ENTER;
  }
}
