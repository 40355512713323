import { withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { AppState } from "../../../store/rootReducer";


interface OwnProps {
  numSelected: number;
  order?: string;
  orderBy?: string;
  onSelectAllClick: any;
  rowCount: number;
  onRequestSort: (event: any, property: any) => void;
}

interface StateProps {
  selectedUserList: string[];
  classes: any;
}

type Props = OwnProps & StateProps/* & DispatchProps*/;

export function mapStateToProps(state: AppState): StateProps {
  return {
    selectedUserList: state.userAdmin.selectedUserList,
    classes: {},
  };
}

const rows = [
  { id: "username", numeric: false, disablePadding: true, label: "brukernavn" },
  { id: "firstname", numeric: false, disablePadding: false, label: "Fornavn" },
  { id: "lastname", numeric: false, disablePadding: false, label: "Etternavn" },
  { id: "email", numeric: false, disablePadding: false, label: "Epost" },
  { id: "operatorId", numeric: true, disablePadding: false, label: "Operator ID" },
  { id: "lastLogonTimestamp", numeric: true, disablePadding: false, label: "Siste aktivitet" },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

class UserListTableHead extends Component<Props> {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.tableCell}>
            <Checkbox
              indeterminate={this.props.selectedUserList.length > 0 && this.props.selectedUserList.length < this.props.rowCount}
              checked={this.props.selectedUserList.length === this.props.rowCount}
              onChange={this.props.onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell className={classes.tableCell}
                key={row.id}
                align={row.numeric ? "center" : "center"}
                padding={row.disablePadding ? "normal" : "normal"}
                sortDirection={(this.props.orderBy === row.id ? (this.props.order === "desc" ? "desc" : "asc") : undefined)/**/}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  {row.id !== 'action' ? <TableSortLabel
                    active={this.props.orderBy === row.id}
                    direction={(this.props.order === "desc" ? "desc" : "asc")}
                    onClick={this.createSortHandler(row.id)}
                  >{row.label}</TableSortLabel> : <>{row.label + ''}</>}
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}
//
const styles = () => ({ tableCell: { padding: "0px 0px", width: '20px', }, });

export default connect<StateProps, undefined, OwnProps, AppState>(mapStateToProps, null)(withStyles(styles as any)(UserListTableHead));