import React from 'react';
import logosimple from '../assets/images/logosimple.svg';

const SVGLogoSimple = (props) => {
  return (
    <div className="vitec-logo">
      <img src={logosimple} width={props.width} alt="Vitec-logo" />
    </div>
  );
};

export default SVGLogoSimple;
