import React from 'react';
import profil from '../assets/images/poweroff.svg';

const SVGPowerOff = () => {
  return (
    <div className="vitec-PowerOff">
      <img src={profil} alt="Vitec-PowerOff" />
    </div>
  );
};

export default SVGPowerOff;
