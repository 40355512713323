import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVGHeaderLogo from './SVGHeaderLogo';
import UISize from '../models/UISize';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      height: 40,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${340}px)`,
      marginLeft: 340,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    rot: {
      height: 172,
      width: '100%',
      display: 'flex',
      marginTop: '0',
      marginLeft: '0',
    },
    logo: {
      opacity: 0.9,
      width: '500px',
      marginTop: 45,
      fontSize: '1em',
    },
    onl: {
      paddingLeft: 15,
      marginTop: 55,
      color: '#fff',
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '4em',
      fontWeight: 100,
      opacity: 0.75,
    },
    mB: {
      marginLeft: 3,
      marginTop: -55,
      color: '#2e2d2c',
      opacity: 1,
    },
    mBB: {
      marginLeft: 3,
      marginTop: -55,
      color: '#2e2d2c',
      opacity: 1,
    },
    //
    hide: { display: 'none' },
    //
    velkA: {
      visibility: 'visible',
      fontSize: '1em',
      position: 'fixed',
      marginTop: 0,
      color: '#fff',
      marginRight: 0,
      right: 40,
      top: 35,
    },
    /*XXS*/[theme.breakpoints.between(0, 801)]: { onl: { fontSize: '2em', width: '250px', marginTop: 20 }, logo: { fontSize: '1em', width: '250px', marginTop: 17 }, velkA: { top: '35px' }, mB: { marginTop: 0, color: '#fff' }, mBB: { marginTop: 0, color: '#fff' } },
    /*XS */[theme.breakpoints.between(801, 1367)]: { onl: { fontSize: '2em', width: '250px', marginTop: 20 }, logo: { fontSize: '1em', width: '250px', marginTop: 17 }, velkA: { top: '35px' }, mB: { marginTop: 0, color: '#fff' }, mBB: { marginTop: 0, color: '#fff' } },
    /*SM */[theme.breakpoints.between(1367, 1681)]: { onl: { fontSize: '2em', width: '250px', marginTop: '23px' }, logo: { fontSize: '1em', width: '250px', marginTop: 17 }, velkA: { top: '35px' }, mB: { marginTop: 0, color: '#fff' }, mBB: { marginTop: 0, color: '#fff' } },
    /*MD */[theme.breakpoints.between(1681, 1921)]: { onl: { fontSize: '4em', width: '250px', marginTop: 55 }, logo: { fontSize: '1em', width: '500px', marginTop: 45 }, velkA: { top: '35px', fontSize: '2em' }, mB: { marginTop: 0, color: '#fff' }, mBB: { marginTop: 0, color: '#fff' } },
    /*LD */[theme.breakpoints.between(1921, 2561)]: { onl: { fontSize: '4em', width: '250px', marginTop: 55 }, logo: { fontSize: '1em', width: '500px', marginTop: 45 }, velkA: { top: '35px' }, mB: { marginTop: 0, color: '#fff' }, mBB: { marginTop: 0, color: '#fff' } },
    /*XL */[theme.breakpoints.between(2561, 3841)]: { onl: { fontSize: '4em', width: '250px', marginTop: 55 }, logo: { fontSize: '1em', width: '500px', marginTop: 45 }, velkA: { top: '35px' }, mB: { marginTop: 0, color: '#fff' }, mBB: { marginTop: 0, color: '#fff' } },
    /*XXL*/[theme.breakpoints.between(3841, 10000)]: { onl: { fontSize: '4em', width: '250px', marginTop: 55 }, logo: { fontSize: '1em', width: '500px', marginTop: 45 }, velkA: { top: '35px' }, mB: { marginTop: 0, color: '#fff' }, mBB: { marginTop: 0, color: '#fff' } },

    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      logo: {
        marginTop: 13,
        marginLeft: -5,
        width: '140px',
        color: '#fff',
      },

      onl: {
        marginTop: 7,
        paddingLeft: 10,
        color: '#2e2d2c',
        fontSize: '1.7em',
      },

      velkA: {
        fontSize: '1em',
        position: 'fixed',
        marginTop: 0,
        color: '#2e2d2c',
        marginRight: 0,
        right: 20,
        top: 16,
      },
      mB: { marginLeft: -5, marginTop: -8, color: '#2e2d2c' },
      mBB: { marginLeft: -5, marginTop: -8, color: '#2e2d2c' },
    },
  })
);

export default function LPAppBarLogo(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector((state) => (state as AppState).ui.screenSize);
  const drawerOpenState: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
  const isButtonVisible: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);
  const user: any = useSelector((state) => (state as AppState).auth.userJWT);
  //
  useEffect(() => {
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  }, [dispatch, screenSize]);
  //
  const handleDrawerToggle = () => {
    dispatch(actions.setSandwichButtonStateClicked(!isButtonVisible));
  };
  //
  const handleDebugMode = () => {
    dispatch(actions.switchDebugMode());
  };

  //
  return (
    <AppBar elevation={0} position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: isButtonVisible ? true : drawerOpenState })}>
      <div className={classes.rot}>
        <IconButton
          color="inherit"
          aria-label="drawerOpenState drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={clsx({ [classes.mB]: isButtonVisible, [classes.mBB]: !isButtonVisible } /* && classes.hide*/)}
        >
          {!drawerOpenState ? <MenuIcon style={{ fontSize: 30 }} /> : []}
        </IconButton>
        <div className={classes.logo} onClick={handleDebugMode}>
          <SVGHeaderLogo width="100%" />
        </div>
        <div className={classes.onl}>Auto&nbsp;Online</div>
      </div>
      {props.displayWelcome === true ? (
        <div className={classes.velkA}>Velkommen, {user && user.firstName ? user.firstName : ''}</div>
      ) : null}
    </AppBar>
  );
}
