


export default class PDFFormData {
  //
  private _fileName: string;
  private _email: string;
  private _description: string;
  private _layout: string;
  private _formData: FormData;

  //
  constructor(fileName = '', email = '', description = '', layout = '', formData: FormData = new FormData()) {
    this._fileName = fileName;
    this._email = email;
    this._description = description;
    this._layout = layout;
    this._formData = formData;
  }

  get fileName(): string { return this._fileName }
  get email(): string { return this._email; }
  get description(): string { return this._description; }
  get layout(): string { return this._layout; }
  get formData(): FormData { return this._formData; }
}

