export default class UserCred {
  private _username: string;
  private _password: string;
  private _authorization: string;
  private _isLogged: boolean;

  constructor(username: string, password = '', authorization = '', isLogged = false) {
    this._username = username;
    this._password = password;
    this._authorization = authorization;
    this._isLogged = isLogged;
  }

  get username(): string {
    return this._username.toUpperCase();
  }

  get password(): string {
    return this._password;
  }

  get authorization(): string {
    return this._authorization;
  }

  get isLogged(): boolean {
    return this._isLogged;
  }
}
