// Utility class - helper ref screen rez.
export default class UtilsCSS {
  //
  static XXS = 0;
  static XXX = 801;
  //
  static XS = 801;
  static XSX = 1367;
  //
  static SM = 1367;
  static SMX = 1681;
  //
  static MD = 1681;
  static MDX = 1921;
  //
  static LG = 1921;
  static LGX = 2561;
  //
  static XL = 2561;
  static XLX = 3841;
  //
  static XXL = 3841;
  static YLX = 10000;
  
  // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
  static IPAD_10 = '@media only screen and (min-width: 1024px) and (max-height: 1366px)'
    + ' and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)';
  //
  static SAMSUNG = '@media only screen and (min-width: 1205px) and (max-height: 650px)'
    + ' and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)';
  //
  static r = (theme: any, x: number, y: number) => {
    return theme.breakpoints.between(x, y);
  }
}

/*
[C.r(t, C.XXS, C.XXX)]: {},
[C.r(t, C.XS, C.XSX)]: {},
[C.r(t, C.SM, C.SMX)]: {},
[C.r(t, C.MD, C.MDX)]: {},
[C.r(t, C.LG, C.LGX)]: {},
[C.r(t, C.XL, C.XLX)]: {},
[C.r(t, C.XXL, C.YLX)]: {},
[C.SAMSUNG]: { base: {}, },
[C.IPAD_10]: { base: {}, },
*/