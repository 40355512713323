
import U from '../../felles/Utils';
import * as actionTypes from '../actions/actionTypes';
import * as fromApp from '../rootReducer';

export interface FeatureState extends fromApp.AppState {
  state: StateError;
}

export interface StateError {
  hasError: boolean;
  error: any;
  errorInfo: string;
}

const initialState = {
  hasError: false,
  error: {},
  errorInfo: '',
};


const setHasError = (state: StateError, action: any) => {
  return U.updateStore(state, { hasError: action.payload });
}

const setError = (state: StateError, action: any) => {
  return U.updateStore(state, { hasError: true, error: {...action.payload} });
}

const setErrorInfo = (state: StateError, action: any) => {
  return U.updateStore(state, { hasError: true, errorInfo: action.payload });
}

// ERROR

const errorReducer = (state = initialState, action: any = '') => {
  switch (action.type) {
    case actionTypes.SET_HAS_ERROR:
      return setHasError(state, action);
    case actionTypes.SET_ERROR:
      return setError(state, action);
    case actionTypes.SET_ERROR_INFO:
      return setErrorInfo(state, action);
    default:
      return state;
  }
};

export default errorReducer;
