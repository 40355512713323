
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    }
});

const UserListTableToolbar = props => {
    const dispatch = useDispatch();
    const { classes } = props;
    const selectedUserList = useSelector(state => (state as AppState).userAdmin.selectedUserList);
    const setUserDeletionState = () => dispatch(actions.setUserDeletionState(1));
    const user: any = useSelector((state) => (state as AppState).auth.userJWT);


    //
    useEffect(() => {
        dispatch(actions.setLocationPath(<>Brukeradministrasjon {'>'} Brukerliste for {user?.company?.toString()}</>));
    }, [dispatch, user]);

    return (
        <Toolbar
            className={classNames(classes.root, { [classes.highlight]: selectedUserList.length > 0 })}>
            <div className={classes.title}>
                {selectedUserList.length > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {selectedUserList.length} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">Brukerliste for {user?.company?.toString()}</Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {selectedUserList.length > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete" onClick={() => setUserDeletionState()}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
        </Toolbar>
    );
};

export default withStyles(toolbarStyles)(UserListTableToolbar);
