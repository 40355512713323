export default class LdapUser {

    private _id = 0;
    private _username: string;
    private _firstname: string;
    private _lastname: string;
    private _mobile: string;
    private _company;
    private _displayName: string;
    private _operatorId: string;
    private _userPrincipalName: string;
    private _email: string;
    private _pwdLink = ''
    private _lastLogonTimestamp: string;
    private _memberOf: string[];
    private _groupListFromAD: string[];
    private _sendPWDCreationLink: boolean;


    constructor(username = '', firstname = '', lastname = '', mobile = '', company = '',
        displayName = '', operatorId: string, userPrincipalName = '', email = '', lastLogonTimestamp: string, 
        memberOf: string[] = [], groupListFromAD: string[] = [], sendPWDCreationLink = false) {
        this._username = username;
        this._firstname = firstname;
        this._lastname = lastname;
        this._mobile = mobile;
        this._company = company;
        this._displayName = displayName;
        this._operatorId = operatorId;
        this._userPrincipalName = userPrincipalName;
        this._email = email;
        this._lastLogonTimestamp = lastLogonTimestamp;
        this._memberOf = memberOf;
        this._groupListFromAD = groupListFromAD;
        this._sendPWDCreationLink = sendPWDCreationLink;
    }

    public get id(): number {
        return this._id;
    }

    public set id(value: number) {
        this._id = value;
    }

    public get username(): string {
        return this._username;
    }

    public set username(value: string) {
        this._username = value;
    }

    public get firstname(): string {
        return this._firstname;
    }

    public set firstname(value: string) {
        this._firstname = value;
    }

    public get lastname(): string {
        return this._lastname;
    }

    public set lastname(value: string) {
        this._lastname = value;
    }

    public get mobile(): string {
        return this._mobile;
    }

    public set mobile(value: string) {
        this._mobile = value;
    }

    public get company(): string {
        return this._company;
    }
    
    //companyNr
    public get operatorId(): string {
        return this._operatorId;
    }

    public get email(): string {
        return this._email;
    }

    public set email(value: string) {
        this._email = value;
    }


    public get pwdLink(): string {
        return this._pwdLink;
    }

    public set pwdLink(value: string) {
        this._pwdLink = value;
    }

    public get lastLogonTimestamp(): string {
        return this._lastLogonTimestamp;
    }

    public set lastLogonTimestamp(value: string) {
        this._lastLogonTimestamp = value;
    }

    public get memberOf(): string[] {
        return this._memberOf;
    }

    public set memberOf(value: string[]) {
        this._memberOf = value;
    }

    public get groupListFromAD(): string[] {
        return this._groupListFromAD;
    }

    public set groupListFromAD(value: string[]) {
        this._groupListFromAD = value;
    }


    public get sendPWDCreationLink(): boolean {
        return this._sendPWDCreationLink;
    }

    public set sendPWDCreationLink(value: boolean) {
        this._sendPWDCreationLink = value;
    }
    
}