import React from 'react';
import logosym from '../assets/images/logosym.svg';

const SVGLogoSym = (props) => {
  return (
    <div className="vitec-logo">
      <img src={logosym} width={props.width} alt="Vitec-logo" />
    </div>
  );
};

export default SVGLogoSym;
