import MenuEl from "./MenuEl";

export default class SearchSel {

  private _code: string;
  private _tilpasset: number;
  private _tilpassetBeta: number;
  private _kodeSynlig: number;
  private _labelSynlig: number;
  private _name: string;
  private _indexing: string;
  private _menuItem: MenuEl;

  constructor(code = '', tilpasset = 0/*: number*/, tilpassetBeta = 0/*: number*/, kodeSynlig = 1/*: number*/, labelSynlig = 1/*: number*/, name = '', indexing = '', menuItem: MenuEl = new MenuEl()) {
    this._code = code;
    this._tilpasset = tilpasset;
    this._tilpassetBeta = tilpassetBeta;
    this._kodeSynlig = kodeSynlig;
    this._labelSynlig = labelSynlig;
    this._name = name;
    this._indexing = indexing;
    this._menuItem = menuItem;
  }

  get code(): string {
    return this._code;
  }

  get tilpasset(): number {
    return this._tilpasset;
  }

  get tilpassetBeta(): number {
    return this._tilpassetBeta;
  }

  get kodeSynlig(): number {
    return this._kodeSynlig;
  }

  get labelSynlig(): number {
    return this._labelSynlig;
  }

  get name(): string {
    return this._name;
  }

  get indexing(): string {
    return this._indexing;
  }

  get menuItem(): MenuEl {
    return this._menuItem;
  }
}
