export default class GridLayout {

  public w: number;
  public h: number;
  public x: number;
  public y: number;
  public i: any;
 // public moved: boolean;
 // public static: boolean;
  public minW: number;
  public maxW: number;
  // public minH: number;

  constructor(w: number, h: number, x: number, y: number, i: any,/* moved: boolean, statics: boolean,*/ minW = 0, maxW = 0) {
    this.w = w;
    this.h = h;
    this.x = x;
    this.y = y;
    this.i = i;
  //  this.moved = moved;
  //  this.static = statics;
    this.minW = minW === 0 ? w : minW ;
    this.maxW = maxW === 0 ? w : maxW ;
  //  this.minH = minH === 0 ? 4 : minH ;
  }
}
