import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeaderContent: {
      height: 80, display: 'flex', alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar, justifyContent: 'flex-end',
    },
  }),
);

export default function LPDrawerHeader() {
  const classes = useStyles();
  return (
    <div className={classes.drawerHeaderContent} />
  );
}
