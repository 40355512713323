export default class FirmNavnId {


    private _firmID: number;
    private _firmName: string;
    private _parentFirmID: number;
    private _firmPrefix: string;

    constructor(firmID = 0, firmName = '', parentFirmID = 0,  firmPrefix = '') {
        this._firmID = firmID;
        this._firmName = firmName;
        this._parentFirmID = parentFirmID;
        this._firmPrefix = firmPrefix;
    }

    public get firmID(): number {
        return this._firmID;
    }

    public set firmID(value: number) {
        this._firmID = value;
    }


    public get firmName(): string {
        return this._firmName;
    }

    public set firmName(value: string) {
        this._firmName = value;
    }

    public get parentFirmID(): number {
        return this._parentFirmID;
    }

    public get firmPrefix(): string {
        return this._firmPrefix;
    }

    public set firmPrefix(value: string) {
        this._firmPrefix = value;
    }

}