import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import SVGHelpRed from '../../../components/SVGHelpRed';
import SVGMail from '../../../components/SVGMail';
import SVGPhone from '../../../components/SVGPhone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    gridItem: {
      display: 'flex',
      borderTop: '1px solid #C8C8C8',
      padding: '13px 0',
      '&:first-child': {
        borderTop: '0px solid',
      },

      /* XXS */ [theme.breakpoints.between(0, 801)]: { padding: '7px 0', fontSize: '.7em' },
      /* XS  */ [theme.breakpoints.between(801, 1367)]: { padding: '7px 0', fontSize: '.7em' },
      /* SM  */ [theme.breakpoints.between(1367, 1681)]: { padding: '7px 0', fontSize: '.9em' },
      /* MD  */ [theme.breakpoints.between(1681, 1921)]: { padding: '7px 0', fontSize: '.8em' },
      /* LD  */ [theme.breakpoints.between(1921, 2561)]: { padding: '7px 0', fontSize: '1em' },
      /* XL  */ [theme.breakpoints.between(2561, 3841)]: { fontSize: '1em' },
      /* XXL */ [theme.breakpoints.between(3841, 10000)]: { fontSize: '1em' },
    },
    logo: {
      color: '#333',
      opacity: 0.7,
      padding: 0,

      /* XXS */ [theme.breakpoints.between(0, 801)]: { width: 15, margin: '2px 5px 0px -11x' },
      /* XS  */ [theme.breakpoints.between(801, 1367)]: { width: 15, margin: '2px 5px 0px -11px' },
      /* SM  */ [theme.breakpoints.between(1367, 1681)]: { width: 15, margin: '2px 10px 0px -8px' },
      /* MD  */ [theme.breakpoints.between(1681, 1921)]: { width: 15, margin: '2px 10px 0px -8px' },
      /* LD  */ [theme.breakpoints.between(1921, 2561)]: { width: 25, margin: '4px 10px 0px -8px' },
      /* XL  */ [theme.breakpoints.between(2561, 3841)]: { width: 40, margin: '10px 10px 0px 0px' },
      /* XXL */ [theme.breakpoints.between(3841, 10000)]: { width: 40, margin: '10px 10px 0px 0px' },
    },
    gridItemTxt: {
      paddingLeft: '1px',
      color: '#000',
      width: '90%',
    },
    kontakt: {
      fontWeight: 'bold',
    },


    
 
 
  // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    gridItemTxt: {
      fontSize: '1.2em',
    },
  },

   //Samsung GalaxyTab
   '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
    gridItemTxt: { marginTop: 0, fontSize: '1em' },
  },

    


  })
);

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

export default function CSupport() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.gridItem}>
        <div className={classes.logo}>
          {' '}
          <SVGMail width="30" />
        </div>

        <div>
          {' '}
          <a className={classes.gridItemTxt} href={'mailto:support.autodata@vitecsoftware.com'} style={{  color: '#961932',}}>
            support.autodata@vitecsoftware.com
          </a>
        </div>
      </div>
      <div className={classes.gridItem}>
        <div className={classes.logo}>
          <SVGHelpRed />
        </div>
        <div className={classes.gridItemTxt}>
          <a className={classes.gridItemTxt} href={'https://vitec-autodata.pureservice.com/login '} target="_new" style={{  color: '#961932',}}>
            Selvbetjeningsportal
          </a>
        </div>
      </div>
      <div className={classes.gridItem}>
        <div className={classes.logo}>
          <SVGPhone width="30" />
        </div>
        <div className={classes.gridItemTxt}>
          23 17 20 30 - Telefontid mandag til fredag 08:00 – 16:00
        </div>
      </div>
    </div>
  );
}
