import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 3,
    },

    gridItem: {
      display: 'flex',
      borderTop: '1px solid #c6c6c6',
      padding: '8px 0',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f3f3f3',
      },
      '&:first-child': {
        borderTop: '0px solid',
      },

      /* XXS */[theme.breakpoints.between(0, 801)]: { padding: '8px 0', fontSize: '.8em' },
      /* XS  */[theme.breakpoints.between(801, 1367)]: { padding: '5px 0', fontSize: '.85em' },
      /* SM  */[theme.breakpoints.between(1367, 1681)]: { padding: '10px 0', fontSize: '.9em' },
      /* MD  */[theme.breakpoints.between(1681, 1921)]: { padding: '13px 0', fontSize: '1em' },
      /* LD  */[theme.breakpoints.between(1921, 2561)]: { padding: '15x 0', fontSize: '1em' },
      /* XL  */[theme.breakpoints.between(2561, 3841)]: { padding: '20px 0', fontSize: '1.1em' },
      /* XXL */[theme.breakpoints.between(3841, 10000)]: { fontSize: '1.2em', padding: '28px 0', },
    },
    gridItemTitle: {
      paddingLeft: '0px',
      color: '#333',
    },


    // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
    '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
      gridItem: {
        paddingBottom: 7,
      },
    },




    //Samsung GalaxyTab
    '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
      gridItem: {
        fontSize: '.8em',
        paddingTop: 6,
        margin: 0,
      },
    },


  })
);
export default function CLenker() {
  const classes = useStyles();

  const handleClick = (event: any, l: any) => {
    event.preventDefault();
    const newWindow = window.open(l.alink, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  //
  function generateDOM() {
    return getLenker().map((l, i) => {
      return (
        //}
        <div key={i} className={classes.gridItem} onClick={(e) => handleClick(e, l)}>
          <div className={classes.gridItemTitle}>{l.title}</div>
        </div>
      );
    });
  }
  return <div className={classes.root}>{generateDOM()}</div>;
}

//
function getLenker() {
  const lenker = [
    { title: 'Autodata hjemmeside', alink: 'https://www.vitecsoftware.com/no/produktomrade/auto/' },
    { title: 'Avslutningsdatoer fakturering', alink: 'https://www.vitec-autosystemer.com/informasjon-autodata/avslutningsdatoer/' },
    { title: 'adRegnskap / adLønn', alink: 'https://terminal.autodata.no/RDWeb/Pages/en-US/login.aspx?ReturnUrl=/RDWeb/Pages/en-US/Default.aspx' },
    { title: 'Eye-share', alink: 'https://gw2.autodata.no/tab_adeyeshare_meny.php' },
  ];
  return lenker;
}
