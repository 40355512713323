export default class SimpleMSG {


    private _title: string;
    private _description: string;



    constructor(title: string, description: string) {
        this._title = title;
        this._description = description;
    }


    public get title(): string {
        return this._title;
    }
    /*
        public set title(value: string) {
            this._title = value;
        }
    */

    public get description(): string {
        return this._description;
    }
    /*
        public set description(value: string) {
            this._description = value;
        }
    */


}