import MenuItem from '../../models/MenuItem';
import MenuItemTarget from '../../models/MenuItemTarget';
import * as actionTypes from './actionTypes';

export const menuItemGetInit = () => {
  return { type: actionTypes.MENU_ITEM_GET_INIT };
};
//*/
export const menuItemGet = () => {
  return { type: actionTypes.MENU_ITEM_GET };
};

export const menuItemGetSuccess = (payload:MenuItem[]) => {
  return { type: actionTypes.MENU_ITEM_GET_SUCCESS, payload };
};

export const menuItemGetFailed = (error: any) => {
  return { type: actionTypes.MENU_ITEM_GET_FAILED, error:error };
};

export const menuItemSetTarget = (menuItemTarget:MenuItemTarget) => {
   return { type: actionTypes.NAV_GET_TARGET, menuItemTarget };
};

export const menuItemSetExpanded = (payload:any) => {
  return { type: actionTypes.NAV_SET_EXPANDED, payload };
};

export const menuItemSetSelected = (payload:any) => {
  return { type: actionTypes.NAV_SET_SELECTED, payload };
};


export const menuItemSetJSXItemElements = (payload:any) => {
  return { type: actionTypes.NAV_SET_JSXEL, payload };
};
