export default class TaleFavorittListe {
    private _id: number;
    private _firmId: string;
    private _butikkId: number;
    private _butikk: string;
    private _startDate: Date;
    private _endDate: Date;
    private _visning: string;
    private _brutto: boolean;
    private _betegnelse: string;
    private _merknader: string;
    private _subButikkId: number;
    private _subButikk: string;


    constructor(id: 0, firmId: '', butikkId: 0, butikk: '', startDate: Date, endDate: Date, visning = ''
        , brutto = false, betegnelse = '', merknader = '', subButikkId = 0, subButikk = '') {
        this._id = id
        this._firmId = firmId;
        this._butikkId = butikkId;
        this._butikk = butikk;
        this._startDate = startDate;
        this._endDate = endDate;
        this._visning = visning;
        this._brutto = brutto;
        this._betegnelse = betegnelse;
        this._merknader = merknader;
        this._subButikkId = subButikkId;
        this._subButikk = subButikk;
    }

    get id(): number {
        return this._id;
    }

    get firmId(): string {
        return this._firmId.toUpperCase();
    }

    get butikkId(): number {
        return this._butikkId;
    }

    get butikk(): string {
        return this._butikk;
    }

    get startDate(): Date {
        return this._startDate;
    }

    get endDate(): Date {
        return this._endDate;
    }

    get visning(): string {
        return this._visning;
    }

    get brutto(): boolean {
        return this._brutto;
    }

    get betegnelse(): string {
        return this._betegnelse;
    }

    get merknader(): string {
        return this._merknader;
    }

    get subButikkId(): number {
        return this._subButikkId;
    }

    get subButikk(): string {
        return this._subButikk;
    }
}
