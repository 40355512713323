import { withStyles } from '@material-ui/core/styles';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Utils from '../felles/Utils';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';

interface Props {
  classes: any;
  contextMenuVisible: boolean;
  contextMenuX: number;
  contextMenuY: number;
  transidRedirect: string;
  tilpasset: string;
  style: any;
  setContextMenuVisibility: (val: boolean) => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAccountungJobList: () => dispatch(actions.accountingJobListGet()),
    onRequestSelected: (Selected: number[]) => dispatch(actions.accountingJobListSelectedUpdated(Selected)),
    setContextMenuVisibility: (val: boolean) => dispatch(actions.setContextMenuVisible(val)),
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    data: state.accounting.accountingJobList,
    isFetchingACC1List: state.accounting.isFetchingACC1List,
    numSelectedJob: state.accounting.selectedJobList,
    contextMenuVisible: state.ui.contextMenuVisible,
    contextMenuX: state.ui.contextMenuX,
    contextMenuY: state.ui.contextMenuY,
    transidRedirect: state.ui.transidRedirect,
    tilpasset: state.ui.tilpasset,
  };
};

const styles = () => ({
  contextMenu: {
    position: 'fixed', background: 'white', border: '1px solid #ccc', boxShadow: '0px 5px 10px #999999', with: 'auto',
    minWidth: 250, zIndex: 3000,
  },
  contextMenuOption: {
    minHeight: 40, background: 'white', paddingTop: 10, paddingLeft: 10, borderBottom: '1px solid #ccc', minWidth: '160px',
    cursor: 'default',
    '&:last-child': { border: 'none', },
    '&:hover': { background: ' linear-gradient(to top, #7c7980, #333)', color: 'white', cursor: 'pointer', },
    '&:active': { color: '#e9e9e9', background: 'linear-gradient(to top, #555, #444)', },
    '&__disabled': { color: '#999999', pointerEvents: 'none', },
  },
});

class RightClickMenu extends Component<Props> {
  ctxMeny: any = createRef();
  //
  componentDidMount() { document.addEventListener('contextmenu', this._handleContextMenu); }

  //
  componentDidUpdate() { document.addEventListener('click', this._handleClick); }
  //
  componentWillUnmount() {
    document.removeEventListener('contextmenu', this._handleContextMenu);
    document.removeEventListener('click', this._handleClick);
  }
  //
  _handleContextMenu = (event) => { event.preventDefault(); };
  //
  _handleClick = (event) => {
    const wasOutside = !(event.target.contains === this.ctxMeny);
    if (wasOutside && this.props.contextMenuVisible) {
      this.props.setContextMenuVisibility(false);
    }
  };
  //
  _mouseClickEventHanmdler = (event, windowed: boolean = false) => {
    event.preventDefault();
    const url = Utils.HOMEPAGE_CONTEXT + (this.props.tilpasset === '0' ? '/vse?transid=' : '/') + this.props.transidRedirect;
    const childWindow = !windowed ? window.open(url, '_blank') : window.open(url, '_blank', 'location=no,status=no');
    childWindow?.sessionStorage.clear(); //TODO remove and test
    childWindow?.location.reload(); //TODO remove and test
  };

  render() {
    const { classes } = this.props;
    return (
      (this.props.contextMenuVisible || null) && (
        <div style={{ left: this.props.contextMenuX, top: this.props.contextMenuY }} ref={this.ctxMeny} className={classes.contextMenu}>
          <div
            className={classes.contextMenuOption}
            onClick={(e) => this._mouseClickEventHanmdler(e)}
          >
            Åpne <strong>{this.props.transidRedirect}</strong> i en ny fane.
          </div>
          <div
            className={classes.contextMenuOption}
            onClick={(e) => this._mouseClickEventHanmdler(e, true)}
          >
            Åpne <strong>{this.props.transidRedirect}</strong> i et nytt vindu.
          </div>
        </div>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(RightClickMenu));
