import { Theme, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { connect } from 'react-redux';
import Utils from '../../felles/Utils';
import UISize from '../../models/UISize';
import * as actions from '../../store/actions/index';
import { AppState } from '../../store/rootReducer';
import LPAppBarLogo from '../../components/LPAppBarLogo';
import LPContentCard from '../../components/LPContentCard';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
    //border: '2px solid red'
  },
  gridItem: {
    /* border: '1px solid #7c7980'*/
  },

  debug: {
    position: 'absolute',
    display: ' inline-block',
    verticalAlign: ' top',
    fontSize: '1em',
    top: 0,
    right: 0,
  },

  /* XXS */ [theme.breakpoints.between(0, 801)]: { root: { marginTop: 0 } },
  /* XS  */ [theme.breakpoints.between(801, 1367)]: { root: { marginTop: 0 } },
  /* SM  */ [theme.breakpoints.between(1367, 1681)]: { root: { marginTop: -20 } },
  /* MD  */ [theme.breakpoints.between(1681, 1921)]: { root: { marginTop: 0 } },
  /* LD  */ [theme.breakpoints.between(1921, 2561)]: { root: { marginTop: 0 } },
  /* XL  */ [theme.breakpoints.between(2561, 3841)]: { root: { marginTop: 0 } },
  /* XXL */ [theme.breakpoints.between(3841, 10000)]: { root: { marginTop: 0 } },

  // Apple 10.2-inch iPad Wi-Fi + Cellular - 9. generasjon
  '@media only screen and (min-width: 1024px) and (max-height: 1366px)  and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 1.5)': {
    root: { marginTop: 0 },
  },

  //Samsung GalaxyTab
  '@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)': {
    root: { marginTop: 0 },
  },
});

interface Props {
  setLayouts: (layout: any, layouts: any) => void;
  setLayout: (layout) => void;
  setMoutedState: (val: boolean) => void;
  setDrawerState: (val: boolean) => void;
  setBreakpointState: (val: any) => void;
  setCompactType: (val: any) => void;
  onBreakpointChange: (val: any) => void;
  onCompactTypeChange: (val: any) => void;
  onLayoutChange: (val: any) => void;
  setSandwichButtonStateClicked: (val: any) => void;

  currentBreakpoint: string;
  compactType: any;
  mounted: boolean;
  layouts: any;
  layout: any;
  classes: any;
  drawerWidth: number;
  gridWidth: number;
  cols: any;
  width: number;
  //height: number;
  size: any;
  screenSize: UISize;
  isDebugEnabled: boolean;
  button: boolean;
  charCode:string;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLayouts: (layout, layouts) => dispatch(actions.setGridLayouts(layout, layouts)),
    setLayout: (layout) => dispatch(actions.setGridLayout(layout)),
    setMoutedState: (val) => dispatch(actions.setMouted(val)),
    setDrawerState: (val) => dispatch(actions.setDrawerState(val)),
    setBreakpointState: (val) => dispatch(actions.setCurrentBreakPoint(val)),
    setSandwichButtonStateClicked: (val) => dispatch(actions.setSandwichButtonStateClicked(val)),
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    currentBreakpoint: state.ui.currentBreakpoint,
    compactType: state.ui.compactType,
    mounted: state.ui.mounted,
    layout: state.ui.layout,
    layouts: state.ui.layouts,
    drawerWidth: state.ui.drawerWidth,
    gridWidth: state.ui.gridWidth,
    cols: state.ui.cols,
    //height: state.ui.height,
    screenSize: state.ui.screenSize,
    isDebugEnabled: state.utils.isDebugEnabled,
    button: state.ui.isSandwichButtonTurnedOn,

    charCode: state.utils.charCode,
  };
};

class LPContentDefault extends Component<Props> {
  //Init
  componentDidMount() {
    this.props.setLayouts(this.generateLayout(this.props.currentBreakpoint), getLayouts(this.props.button));
    if (this.props.currentBreakpoint === 'xs' || this.props.currentBreakpoint === 'xxs') this.props.setDrawerState(false);
    this.props.setMoutedState(true);
  }

  generateLayout = (val) => {
    const layouts = getLayouts(false);
    return layouts[val];
  };

  hBreak = '';
  onBreakpointChange = (breakpoint) => {
    this.hBreak = breakpoint;
  };

  //
  onCompactTypeChange = () => {
    return this.props.setCompactType('vertical');
  };

  //
  onLayoutChange = () => {
    return this.props.setLayouts(this.generateLayout(this.props.currentBreakpoint), getLayouts(this.props.button));
  };

  //
  generateDOM() {
    const { classes } = this.props;
    if (this.props.layouts && this.props.layout) {
      return this.props.layout.map((l, i) => {
        return (
          <div className={classes.gridItem} key={i}>
            <LPContentCard isDebugEnabled={this.props.isDebugEnabled} cardType={i} title={l.ti} link={l.li} />
          </div>
        );
      });
    } else return [];
  }

  getRowHeight = (bT: boolean) => {
    let rowHeight = 125;
    const w = this.props.screenSize.width;
    switch (true) {
      /*XXS*/ case w <= 501:
        rowHeight = 145;
        break;
      /*XS*/ case w >= 501 && w < 1026:
        bT ? (rowHeight = 170) : (rowHeight = 157);
        break;
      /*SM*/ case w >= 1026 && w < 1341:
        bT ? (rowHeight = 170) : (rowHeight = 135);
        break;
      /*SM*/ case w >= 1341 && w < 1580:
        rowHeight = 145;
        break;
      /*SM*/ case w >= 1580 && w < 2220:
        rowHeight = 145;
        break;
      /*SM*/ case w >= 2220 && w < 3500:
        rowHeight = 145;
        break;
      /*SM*/ case w > 3500:
        rowHeight = 145;
        break;
      // TOADD Specials case for tablets
    }
    return rowHeight;
  };

  render() {
    //
    const { /* size,*/ classes } = this.props;
    //
    return (
      <>
        <LPAppBarLogo displayWelcome={true} />
        {Utils.IS_ENV_DEV(this.props.isDebugEnabled) ? (
          <>
            {' '}
            <div className={classes.debug}>
              {this.props.button + ' | '}
              {this.props.currentBreakpoint + ' | '}
              {this.props.screenSize ? <>{' charEnc: ' +  this.props.charCode +    ' width: ' + this.props.screenSize.width + ' height: ' + this.props.screenSize.height + ' '}</> : null}
              {this.props.size ? <>{' ||| innerDiv width: ' + this.props.size.width}</> : null}
            </div>
          </>
        ) : null}
        <Responsive
          className={classes.root}
          layouts={this.props.layouts}
          width={this.props.size.width || 100}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          measureBeforeMount={false}
          useCSSTransforms={this.props.mounted}
          isResizable={false}
          isDraggable={false}
          rowHeight={this.getRowHeight(this.props.button)}
          breakpoints={{ xxl: 3500, xl: 2220, lg: 1580, md: 1341, sm: 1026, xs: 500, xxs: 0 }}
          cols={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
          margin={[20, 20]} //
        >
          {this.generateDOM()}
        </Responsive>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(LPContentDefault));

//
function getLayouts(bT) {
  const DRIFTSTATUS = 'Driftstatus';
  const AUTODATA = 'Autodata Web';
  const NYHETER = 'Nyheter';
  const SUPPORT = 'Kontakt Oss';
  const LENKER = 'Nyttige Lenker';
  const LID = 'https://www.vitecsoftware.com/no/produktomrade/auto/driftsmeldinger/';
  const LIN = 'https://www.vitecsoftware.com/no/produktomrade/auto/nyheter/';

  const layouts = {
    xxl: [
      // over 2500
      { w: 9, h: 7, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 2.45, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 7, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 2, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 2.3, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    xl: [
      // over 2500
      { w: 9, h: 7, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 2.45, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 7, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 2.3, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 2.3, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    lg: [
      // 2561
      { w: 9, h: 5, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 2.1, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 5, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 1.8, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 1.5, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    md: [
      // 1920
      { w: 9, h: 4.5, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 6, h: 1.6, x: 9, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 9, h: 4.5, x: 18, y: 0, i: '2', ti: NYHETER, li: LIN },
      { w: 6, h: 1.45, x: 9, y: 2, i: '3', ti: SUPPORT, li: '' },
      { w: 6, h: 1.74, x: 9, y: 4, i: '4', ti: LENKER, li: '' },
    ],
    sm: [
      // 1366
      { w: 16, h: 2.08, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: 8, h: 1.43, x: 16, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: 16, h: 2.08, x: 0, y: 1, i: '2', ti: NYHETER, li: LIN },
      { w: 8, h: 1.34, x: 16, y: 1, i: '3', ti: SUPPORT, li: '' },
      { w: 8, h: 1.4, x: 16, y: 2, i: '4', ti: LENKER, li: '' },
    ],
    xs: [
      { w: bT ? 14 : 16, h: 1.92, x: 0, y: 0, i: '0', ti: DRIFTSTATUS, li: LID },
      { w: bT ? 10 : 8, h: 1.3, x: 16, y: 0, i: '1', ti: AUTODATA, li: '' },
      { w: bT ? 14 : 16, h: 1.5, x: 0, y: 1, i: '2', ti: NYHETER, li: LIN },
      { w: bT ? 10 : 8, h: 1.11, x: 16, y: 1, i: '3', ti: SUPPORT, li: '' },
      { w: bT ? 10 : 8, h: 1, x: 16, y: 2, i: '4', ti: LENKER, li: '' },
    ],
    xxs: [
      { w: 24, h: 1.2, x: 0, y: 0, i: '0', ti: AUTODATA, li: '' },
      { w: 24, h: 1.28, x: 0, y: 1, i: '1', ti: DRIFTSTATUS, li: LID },
      { w: 24, h: 1.28, x: 0, y: 2, i: '2', ti: NYHETER, li: LIN },
      { w: 24, h: 1.2, x: 0, y: 3, i: '3', ti: SUPPORT, li: '' },
      { w: 24, h: 1.2, x: 0, y: 4, i: '4', ti: LENKER, li: '' },
    ],
  };
  return layouts;
}
